define('ember-inline-edit/helpers/in-arr', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.inArr = inArr;
  function inArr(params /*, hash*/) {
    const haystack = params[0];
    const needle = params[1];

    if (Ember.isEmpty(haystack) || Ember.isEmpty(needle)) {
      Ember.warn('in-arr params cannot be empty');
      return;
    }

    return haystack.indexOf(needle) > -1;
  }

  exports.default = Ember.Helper.helper(inArr);
});