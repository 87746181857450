define("ember-cli-string-helpers/helpers/classify", ["exports", "ember-cli-string-helpers/-private/create-string-helper"], function (_exports, _createStringHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.classify = void 0;
  const classify = (0, _createStringHelper.default)(Ember.String.classify);
  _exports.classify = classify;
  var _default = Ember.Helper.helper(classify);
  _exports.default = _default;
});