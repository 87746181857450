define('ember-group-by/macros/group-by', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = groupBy;
  function groupBy(collection, property) {
    let dependentKey = collection + '.@each.' + property;

    return Ember.computed(dependentKey, function () {
      let groups = Ember.A();
      let items = Ember.get(this, collection);

      if (items) {
        items.forEach(function (item) {
          let value = Ember.get(item, property);
          let group = groups.findBy('value', value);

          if (Ember.isPresent(group)) {
            Ember.get(group, 'items').push(item);
          } else {
            group = { property: property, value: value, items: [item] };
            groups.push(group);
          }
        });
      }

      return groups;
    }).readOnly();
  }
});