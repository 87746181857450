define("ember-power-calendar/services/power-calendar", ["exports", "ember-power-calendar-utils"], function (_exports, _emberPowerCalendarUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class2;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let _class = (_dec = Ember.computed, (_class2 = class _class2 extends Ember.Service {
    constructor() {
      super(...arguments);
      _defineProperty(this, "date", null);
    }
    // CPs
    get locale() {
      return (0, _emberPowerCalendarUtils.getDefaultLocale)();
    }
    set locale(value) {
      return value;
    }

    // Methods
    getDate() {
      return this.date || new Date();
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "locale", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "locale"), _class2.prototype)), _class2));
  _exports.default = _class;
});