define('ember-chrome-devtools/initializers/install-devtools', ['exports', 'ember-chrome-devtools/formatters'], function (exports, _formatters) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* application */{
    const old = window.devtoolsFormatters || [];
    window.devtoolsFormatters = [...old, ..._formatters.default];
  }

  exports.default = {
    name: 'install-devtools',
    initialize
  };
});