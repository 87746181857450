define("ember-yeti-table/components/yeti-table/thead/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "I0N6VyEb",
    "block": "{\"symbols\":[\"@theme\",\"&attrs\",\"@parent\",\"@columns\",\"@onColumnClick\",\"@sortSequence\",\"@sortable\",\"&default\"],\"statements\":[[11,\"thead\"],[16,0,[32,1,[\"thead\"]]],[17,2],[12],[2,\"\\n  \"],[18,8,[[30,[36,1],null,[[\"row\"],[[30,[36,0],[\"ember-yeti-table@yeti-table/thead/row/component\"],[[\"sortable\",\"sortSequence\",\"onColumnClick\",\"columns\",\"theme\",\"parent\"],[[32,7],[32,6],[32,5],[32,4],[32,1],[32,3]]]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "ember-yeti-table/components/yeti-table/thead/template.hbs"
  });
  _exports.default = _default;
});