define("@ember/legacy-built-in-components/templates/empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xx6bL0p+",
    "block": "{\"symbols\":[],\"statements\":[],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "@ember/legacy-built-in-components/templates/empty.hbs"
  });
  _exports.default = _default;
});