define("ember-yeti-table/components/yeti-table/component", ["exports", "@ember-decorators/component", "deepmerge", "ember-yeti-table/-private/themes/default-theme", "ember-yeti-table/-private/utils/default-to", "ember-yeti-table/-private/utils/did-change-attrs-component", "ember-yeti-table/-private/utils/filtering-utils", "ember-yeti-table/-private/utils/sorting-utils", "ember-yeti-table/components/yeti-table/template"], function (_exports, _component, _deepmerge, _defaultTheme, _defaultTo, _didChangeAttrsComponent, _filteringUtils, _sortingUtils, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  /**
   * bring ember-concurrency didCancel helper instead of
   * including the whole dependency
   */
  const TASK_CANCELATION_NAME = 'TaskCancelation';
  const didCancel = function (e) {
    return e && e.name === TASK_CANCELATION_NAME;
  };

  /**
    The primary Yeti Table component. This component represents the root of the
    table, and manages high level state of all of its subcomponents.
  
    ```hbs
    <YetiTable @data={{this.data}} as |table|>
  
      <table.header as |header|>
        <header.column @prop="firstName">
          First name
        </header.column>
        <header.column @prop="lastName">
          Last name
        </header.column>
        <header.column @prop="points">
          Points
        </header.column>
      </table.header>
  
      <table.body/>
  
    </YetiTable>
    ```
  
    ```hbs
    <YetiTable @data={{this.data}} as |table|>
  
      <table.thead as |thead|>
        <thead.row as |row|>
          <row.column @prop="firstName">
            First name
          </row.column>
          <row.column @prop="lastName">
            Last name
          </row.column>
          <row.column @prop="points">
            Points
          </row.column>
        </thead.row>
      </table.thead>
  
      <table.body/>
  
    </YetiTable>
    ```
  
    @yield {object} table
    @yield {Component} table.header       the table header component (Single row in header)
    @yield {Component} table.thead        the table header component (Allows multiple rows in header)
    @yield {Component} table.body         the table body component
    @yield {Component} table.tfoot        the table footer component
    @yield {Component} table.pagination   the pagination controls component
    @yield {object} table.actions         an object that contains actions to interact with the table
    @yield {object} table.paginationData  object that represents the current pagination state
    @yield {boolean} table.isLoading      boolean that is `true` when data is being loaded
    @yield {array} table.columns          the columns on the table
    @yield {array} table.visibleColumns   the visible columns on the table
    @yield {array} table.rows             an array of all the rows yeti table knows of. In the sync case, it will contain the entire dataset. In the async case, it will be the same as `table.visibleRows`
    @yield {number} table.totalRows       the total number of rows on the table (regardless of pagination). Important argument in the async case to inform yeti table of the total number of rows in the dataset.
    @yield {array} table.visibleRows      the rendered rows on the table account for pagination, filtering, etc; when pagination is false, it will be the same length as totalRows
    @yield {object} table.theme           the theme being used
  */
  let YetiTable = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = (0, _defaultTo.default)(''), _dec4 = Ember.computed('theme', 'config.theme'), _dec5 = Ember.computed.filterBy('columns', 'visible', true), _dec6 = Ember.computed, _dec7 = Ember.computed('loadData', 'sortedData.[]', 'resolvedData.[]', 'totalRows'), _dec8 = Ember.computed('loadData', 'sortedData.[]', 'resolvedData.[]'), _dec9 = Ember.computed('pageSize', 'pageNumber', 'normalizedTotalRows'), _dec10 = Ember.computed('sortedData.[]', 'pagination', 'paginationData'), _dec11 = Ember.computed('pagedData', 'resolvedData', 'loadData'), _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class YetiTable extends _didChangeAttrsComponent.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "theme", {});
      _defineProperty(this, "data", void 0);
      _defineProperty(this, "loadData", void 0);
      _defineProperty(this, "publicApi", {
        previousPage: this.previousPage,
        nextPage: this.nextPage,
        goToPage: this.goToPage,
        changePageSize: this.changePageSize,
        reloadData: this.runLoadData
      });
      _defineProperty(this, "registerApi", void 0);
      _defineProperty(this, "pagination", this.get('config').pagination === undefined ? false : this.get('config').pagination);
      _defineProperty(this, "pageSize", this.get('config').pageSize || 15);
      _defineProperty(this, "pageNumber", 1);
      _defineProperty(this, "totalRows", void 0);
      _initializerDefineProperty(this, "filter", _descriptor, this);
      _defineProperty(this, "filterFunction", void 0);
      _defineProperty(this, "filterUsing", void 0);
      _defineProperty(this, "sortable", this.get('config').sortable === undefined ? true : this.get('config').sortable);
      _defineProperty(this, "sortFunction", _sortingUtils.sortMultiple);
      _defineProperty(this, "compareFunction", _sortingUtils.compareValues);
      _defineProperty(this, "sortSequence", this.get('config').sortSequence || ['asc', 'desc']);
      _defineProperty(this, "ignoreDataChanges", false);
      _defineProperty(this, "renderTableElement", true);
      _defineProperty(this, "isColumnVisible", void 0);
      _defineProperty(this, "isLoading", false);
      _initializerDefineProperty(this, "visibleColumns", _descriptor2, this);
    }
    // If the theme is replaced, this will invalidate, but not if any prop under theme is changed
    get mergedTheme() {
      let configTheme = this.get('config').theme || {};
      let localTheme = this.get('theme');
      return _deepmerge.default.all([_defaultTheme.default, configTheme, localTheme]);
    }
    get config() {
      return Ember.getOwner(this).resolveRegistration('config:environment')['ember-yeti-table'] || {};
    }
    get normalizedTotalRows() {
      if (!this.get('loadData')) {
        // sync scenario using @data
        return this.get('sortedData.length');
      } else {
        // async scenario. @loadData is present.
        if (this.get('totalRows') === undefined) {
          // @totalRows was not passed in. Use the returned data set length.
          return this.get('resolvedData.length');
        } else {
          // @totalRows was passed in.
          return this.get('totalRows');
        }
      }
    }
    get normalizedRows() {
      if (!this.get('loadData')) {
        // sync scenario using @data
        return this.get('sortedData');
      } else {
        // async scenario. @loadData is present.
        return this.get('resolvedData');
      }
    }
    get paginationData() {
      let pageSize = this.get('pageSize');
      let pageNumber = this.get('pageNumber');
      let totalRows = this.get('normalizedTotalRows');
      let isLastPage, totalPages;
      if (totalRows) {
        totalPages = Math.ceil(totalRows / pageSize);
        pageNumber = Math.min(pageNumber, totalPages);
        isLastPage = pageNumber === totalPages;
      }
      let isFirstPage = pageNumber === 1;
      let pageStart = (pageNumber - 1) * pageSize;
      let pageEnd = pageStart + pageSize - 1;

      // make pageStart and pageEnd 1-indexed
      pageStart += 1;
      pageEnd += 1;
      if (totalRows) {
        pageEnd = Math.min(pageEnd, totalRows);
      }
      return {
        pageSize,
        pageNumber,
        pageStart,
        pageEnd,
        isFirstPage,
        isLastPage,
        totalRows,
        totalPages
      };
    }
    get pagedData() {
      let pagination = this.get('pagination');
      let data = this.get('sortedData');
      if (pagination) {
        let {
          pageStart,
          pageEnd
        } = this.get('paginationData');
        data = data.slice(pageStart - 1, pageEnd); // slice excludes last element so we don't need to subtract 1
      }

      return data;
    }
    get processedData() {
      if (this.get('loadData')) {
        // skip processing and return raw data if remote data is enabled via `loadData`
        return this.get('resolvedData');
      } else {
        return this.get('pagedData');
      }
    }
    init() {
      super.init(...arguments);
      this.columns = [];
      this.filteredData = [];
      this.sortedData = [];
      this.resolvedData = [];
      this.didChangeAttrsConfig = {
        attrs: ['filter', 'filterUsing', 'pageSize', 'pageNumber']
      };
      if (this.registerApi) {
        Ember.run.scheduleOnce('actions', null, this.registerApi, this.publicApi);
      }
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      let oldData = this._oldData;
      let data = this.get('data');
      if (data !== oldData) {
        if (data && data.then) {
          this.set('isLoading', true);
          data.then(resolvedData => {
            // check if data is still the same promise
            if (data === this.get('data') && !this.isDestroyed) {
              this.set('resolvedData', resolvedData);
              this.set('isLoading', false);
            }
          }).catch(e => {
            if (!didCancel(e)) {
              if (!this.isDestroyed) {
                this.set('isLoading', false);
              }
              // re-throw the non-cancellation error
              throw e;
            }
          });
        } else {
          this.set('resolvedData', data || []);
        }
      }
      this._oldData = data;
    }
    didChangeAttrs() {
      this.runLoadData();
    }
    didInsertElement() {
      super.didInsertElement(...arguments);
      let depKeys = '[]';
      if (this.get('ignoreDataChanges') === false) {
        // Only include columns with a prop
        // truncate prop names to the first period
        // get a unique list
        let columns = this.get('columns').filter(column => column.get('prop')).map(column => column.get('prop').split('.')[0]).filter((v, i, a) => a.indexOf(v) === i);
        if (columns.length > 0) {
          depKeys = `@each.{${columns.join(',')}}`;
        }
      }
      let filteredDataDeps = [`resolvedData.${depKeys}`, 'columns.@each.{prop,filterFunction,filter,filterUsing,filterable}', 'filter', 'filterUsing', 'filterFunction'];
      Ember.defineProperty(this, 'filteredData', Ember.computed(...filteredDataDeps, function () {
        let data = this.get('resolvedData');
        // only columns that have filterable = true and a prop defined will be considered
        let columns = this.get('columns').filter(c => c.get('filterable') && Ember.isPresent(c.get('prop')));
        let filter = this.get('filter');
        let filterFunction = this.get('filterFunction');
        let filterUsing = this.get('filterUsing');
        return (0, _filteringUtils.default)(data, columns, filter, filterFunction, filterUsing);
      }));
      let sortedDataDeps = [`filteredData.${depKeys}`, 'columns.@each.{prop,sort,sortable}', 'sortFunction', 'compareFunction'];
      Ember.defineProperty(this, 'sortedData', Ember.computed(...sortedDataDeps, function () {
        let data = this.get('filteredData');
        let sortableColumns = this.get('columns').filter(c => !Ember.isEmpty(c.get('sort')));
        let sortings = sortableColumns.map(c => ({
          prop: c.get('prop'),
          direction: c.get('sort')
        }));
        let sortFunction = this.get('sortFunction');
        let compareFunction = this.get('compareFunction');
        if (sortings.length > 0) {
          data = (0, _sortingUtils.mergeSort)(data, (itemA, itemB) => {
            return sortFunction(itemA, itemB, sortings, compareFunction);
          });
        }
        return data;
      }));

      // defining a computed property on didInsertElement doesn't seem
      // to trigger any updates. This forces an update.
      this.notifyPropertyChange('filteredData');
      this.notifyPropertyChange('sortedData');
      this.runLoadData();
    }
    runLoadData() {
      let loadData = this.get('loadData');
      if (loadData) {
        let loadDataFunction = async () => {
          let loadData = this.get('loadData');
          if (typeof loadData === 'function') {
            let param = {};
            if (this.get('pagination')) {
              param.paginationData = this.get('paginationData');
            }
            param.sortData = this.get('columns').filter(c => !Ember.isEmpty(c.get('sort'))).map(c => ({
              prop: c.get('prop'),
              direction: c.get('sort')
            }));
            param.filterData = {
              filter: this.get('filter'),
              filterUsing: this.get('filterUsing'),
              columnFilters: this.get('columns').map(c => ({
                prop: c.get('prop'),
                filter: c.get('filter'),
                filterUsing: c.get('filterUsing')
              }))
            };
            let promise = loadData(param);
            if (promise && promise.then) {
              this.set('isLoading', true);
              try {
                let resolvedData = await promise;
                if (!this.isDestroyed) {
                  this.set('resolvedData', resolvedData);
                  this.set('isLoading', false);
                }
              } catch (e) {
                if (!didCancel(e)) {
                  if (!this.isDestroyed) {
                    this.set('isLoading', false);
                  }
                  // re-throw the non-cancelation error
                  throw e;
                }
              }
            } else {
              this.set('resolvedData', promise);
            }
          }
        };
        Ember.run.once(loadDataFunction);
      }
    }
    onColumnSort(column, e) {
      if (column.get('isSorted')) {
        // if this column is already sorted, calculate the next
        // sorting on the sequence.
        let direction = column.get('sort');
        let sortSequence = column.get('normalizedSortSequence');
        direction = sortSequence[(sortSequence.indexOf(direction) + 1) % sortSequence.length];
        if (direction === 'unsorted') {
          direction = null;
        }
        column.set('sort', direction);
        if (!e.shiftKey) {
          // if not pressed shift, reset other column sortings
          let columns = this.get('columns').filter(c => c !== column);
          columns.forEach(c => c.set('sort', null));
        }
      } else {
        // use first direction from sort sequence
        let direction = column.get('normalizedSortSequence')[0];
        // create new sorting
        column.set('sort', direction);

        // normal click replaces all sortings with the new one
        // shift click adds a new sorting to the existing ones
        if (!e.shiftKey) {
          // if not pressed shift, reset other column sortings
          let columns = this.get('columns').filter(c => c !== column);
          columns.forEach(c => c.set('sort', null));
        }
      }
      this.runLoadData();
    }
    previousPage() {
      if (this.get('pagination')) {
        let {
          pageNumber
        } = this.get('paginationData');
        this.setInternalProp('pageNumber', Math.max(pageNumber - 1, 1));
        this.runLoadData();
      }
    }
    nextPage() {
      if (this.get('pagination')) {
        let {
          pageNumber,
          isLastPage
        } = this.get('paginationData');
        if (!isLastPage) {
          this.setInternalProp('pageNumber', pageNumber + 1);
          this.runLoadData();
        }
      }
    }
    goToPage(pageNumber) {
      if (this.get('pagination')) {
        let {
          totalPages
        } = this.get('paginationData');
        pageNumber = Math.max(pageNumber, 1);
        if (totalPages) {
          pageNumber = Math.min(pageNumber, totalPages);
        }
        this.setInternalProp('pageNumber', pageNumber);
        this.runLoadData();
      }
    }
    changePageSize(pageSize) {
      if (this.get('pagination')) {
        this.setInternalProp('pageSize', parseInt(pageSize));
        this.runLoadData();
      }
    }
    registerColumn(column) {
      if (this.isColumnVisible) {
        let visible = this.isColumnVisible(column);
        Ember.set(column, 'visible', visible);
      }
      let columns = this.get('columns');
      if (!columns.includes(column)) {
        columns.push(column);
        let notifyVisibleColumnsPropertyChange = () => this.notifyPropertyChange('visibleColumns');
        Ember.run.once(notifyVisibleColumnsPropertyChange);
      }
    }
    unregisterColumn(column) {
      let columns = this.get('columns');
      if (columns.includes(column)) {
        this.set('columns', columns.filter(c => c !== column));
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "filter", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "mergedTheme", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "mergedTheme"), _class2.prototype), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "visibleColumns", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "config", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "config"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "normalizedTotalRows", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "normalizedTotalRows"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "normalizedRows", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "normalizedRows"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "paginationData", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "paginationData"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "pagedData", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "pagedData"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "processedData", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "processedData"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "runLoadData", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "runLoadData"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onColumnSort", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "onColumnSort"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "previousPage", [_dec14], Object.getOwnPropertyDescriptor(_class2.prototype, "previousPage"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "nextPage", [_dec15], Object.getOwnPropertyDescriptor(_class2.prototype, "nextPage"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "goToPage", [_dec16], Object.getOwnPropertyDescriptor(_class2.prototype, "goToPage"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "changePageSize", [_dec17], Object.getOwnPropertyDescriptor(_class2.prototype, "changePageSize"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = YetiTable;
  _exports.default = _default;
});