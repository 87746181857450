define("ember-yeti-table/components/yeti-table/tfoot/row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ZmJe14Cn",
    "block": "{\"symbols\":[\"@theme\",\"@class\",\"&attrs\",\"&default\"],\"statements\":[[11,\"tr\"],[16,0,[31,[[32,2],\" \",[32,1,[\"tfootRow\"]],\" \",[32,1,[\"row\"]]]]],[17,3],[12],[2,\"\\n  \"],[18,4,[[30,[36,1],null,[[\"cell\"],[[30,[36,0],[\"ember-yeti-table@yeti-table/tfoot/row/cell/component\"],[[\"theme\",\"parent\"],[[32,1],[32,0]]]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "ember-yeti-table/components/yeti-table/tfoot/row/template.hbs"
  });
  _exports.default = _default;
});