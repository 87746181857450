define("ember-clock/initializers/clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  /*
    Inject the clock service into all controllers and components
  */
  function initialize() {
    const app = arguments[1] || arguments[0];
    app.inject('controller', 'clock', 'service:clock');
    app.inject('component', 'clock', 'service:clock');
  }
  var _default = {
    name: 'clock',
    initialize: initialize
  };
  _exports.default = _default;
});