define("ember-data-copyable/utils/get-transform", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getTransform;
  /**
   * Get the transform for a given type. Uses the private `_meta` cache.
   *
   * @method getTransform
   * @private
   * @param  {DS.Model} model
   * @param  {String} type
   * @param  {Object} _meta Copy task meta object
   * @return {DS.Transform}
   */
  function getTransform(model, type, _meta) {
    _meta.transforms[type] = _meta.transforms[type] || Ember.getOwner(model).lookup(`transform:${type}`);
    return _meta.transforms[type];
  }
});