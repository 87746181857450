define("ember-cli-string-helpers/helpers/uppercase", ["exports", "ember-cli-string-helpers/utils/uppercase", "ember-cli-string-helpers/-private/create-string-helper"], function (_exports, _uppercase, _createStringHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.uppercase = _exports.default = void 0;
  const uppercase = (0, _createStringHelper.default)(_uppercase.default);
  _exports.uppercase = uppercase;
  var _default = Ember.Helper.helper(uppercase);
  _exports.default = _default;
});