define('ember-chrome-devtools/formatters/lazy', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  /** Evaluate Ember computed properties and es5 getters when expanded */
  class Lazy {
    constructor(compute) {
      this.compute = compute;
    }
  }

  exports.Lazy = Lazy;
  class LazyFormatter {
    header(obj) {
      if (obj instanceof Lazy) {
        const style = `
        opacity: 0.8;
        font-style: italic;
      `;
        return ['span', { style }, '(...)'];
      }
    }
    hasBody() {
      return true;
    }
    body(obj) {
      return obj.compute();
    }
  }
  exports.LazyFormatter = LazyFormatter;
});