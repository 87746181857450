define('ember-chrome-devtools/formatters/array', ['exports', 'ember-chrome-devtools/formatters/object', 'ember-chrome-devtools/formatters/utils/jsonml'], function (exports, _object, _jsonml) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ArrayFormatter = undefined;
  exports.item = item;
  exports.items = items;
  function item(idx, value) {
    return _jsonml.item(_jsonml.name(idx), _jsonml.separator(), _jsonml.reference(value));
  }

  function* items(obj) {
    for (const [idx, value] of obj.toArray().entries()) {
      yield item(idx, value);
    }
  }

  class ArrayFormatter {
    header(obj) {
      if (obj instanceof Ember.Object && Ember.Array.detect(obj)) {
        const length = Ember.cacheFor(obj, 'length');
        return _jsonml.header(`${obj}(length = ${length || '?'})`);
      }
    }
    hasBody() {
      return true;
    }
    body(obj) {
      return _jsonml.list(...items(obj), ...(0, _object.properties)(obj));
    }
  }
  exports.ArrayFormatter = ArrayFormatter;
});