define('ember-invoke/helpers/invoke', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.invokeFunction = invokeFunction;
  function invokeFunction([context, method, ...rest]) {
    if (typeof context[method] !== 'function') {
      throw new Error(`Method '${method}' is not defined or cannot be invoked.`);
    }

    return context[method].apply(context, rest);
  }

  exports.default = Ember.Helper.helper(invokeFunction);
});