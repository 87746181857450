define("ember-cli-string-helpers/helpers/camelize", ["exports", "ember-cli-string-helpers/-private/create-string-helper"], function (_exports, _createStringHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.camelize = void 0;
  const camelize = (0, _createStringHelper.default)(Ember.String.camelize);
  _exports.camelize = camelize;
  var _default = Ember.Helper.helper(camelize);
  _exports.default = _default;
});