define("ember-yeti-table/components/yeti-table/table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "GtKBjoPB",
    "block": "{\"symbols\":[\"@theme\",\"&attrs\",\"&default\"],\"statements\":[[11,\"table\"],[16,0,[32,1,[\"table\"]]],[17,2],[12],[2,\"\\n  \"],[18,3,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "ember-yeti-table/components/yeti-table/table/template.hbs"
  });
  _exports.default = _default;
});