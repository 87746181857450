define("ember-yeti-table/components/yeti-table/tbody/row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "mIbFfSpK",
    "block": "{\"symbols\":[\"@theme\",\"@onClick\",\"&attrs\",\"&default\"],\"statements\":[[11,\"tr\"],[16,0,[31,[[32,1,[\"tbodyRow\"]],\" \",[32,1,[\"row\"]]]]],[16,\"role\",[30,[36,0],[[32,2],\"button\"],null]],[17,3],[4,[38,1],[\"click\",[32,0,[\"handleClick\"]]],null],[12],[2,\"\\n  \"],[18,4,[[30,[36,3],null,[[\"cell\"],[[30,[36,2],[\"ember-yeti-table@yeti-table/tbody/row/cell/component\"],[[\"theme\",\"parent\"],[[32,1],[32,0]]]]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"on\",\"component\",\"hash\"]}",
    "moduleName": "ember-yeti-table/components/yeti-table/tbody/row/template.hbs"
  });
  _exports.default = _default;
});