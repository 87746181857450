define('ember-cli-is-component/helpers/is-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  const { Helper } = Ember;
  const { service: inject } = Ember.inject;

  exports.default = Helper.extend({
    isComponent: inject(),

    compute([name]) {
      return this.get('isComponent').test(name);
    }
  });
});