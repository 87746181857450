define("ember-cached-decorator-polyfill/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cached = cached;
  function cached() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    const [target, key, descriptor] = args;

    // Error on `@cached()`, `@cached(...args)`, and `@cached propName = value;`
    (true && !(target !== undefined) && Ember.assert('You attempted to use @cached(), which is not necessary nor supported. Remove the parentheses and you will be good to go!', target !== undefined));
    (true && !(typeof target === 'object' && typeof key === 'string' && typeof descriptor === 'object' && args.length === 3) && Ember.assert(`You attempted to use @cached on with ${args.length > 1 ? 'arguments' : 'an argument'} ( @cached(${args.map(d => `'${d}'`).join(', ')}), which is not supported. Dependencies are automatically tracked, so you can just use ${'`@cached`'}`, typeof target === 'object' && typeof key === 'string' && typeof descriptor === 'object' && args.length === 3));
    (true && !(typeof descriptor.get == 'function') && Ember.assert(`The @cached decorator must be applied to getters. '${key}' is not a getter.`, typeof descriptor.get == 'function'));
    const caches = new WeakMap();
    const getter = descriptor.get;
    descriptor.get = function () {
      if (!caches.has(this)) caches.set(this, Ember._createCache(getter.bind(this)));
      return Ember._cacheGetValue(caches.get(this));
    };
  }
});