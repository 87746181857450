define("ember-yeti-table/components/yeti-table/tfoot/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "R3ijuHTx",
    "block": "{\"symbols\":[\"@theme\",\"&attrs\",\"@parent\",\"@columns\",\"&default\"],\"statements\":[[11,\"tfoot\"],[16,0,[32,1,[\"tfoot\"]]],[17,2],[12],[2,\"\\n  \"],[18,5,[[30,[36,1],null,[[\"row\"],[[30,[36,0],[\"ember-yeti-table@yeti-table/tfoot/row/component\"],[[\"columns\",\"theme\",\"parent\"],[[32,4],[32,1],[32,3]]]]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "ember-yeti-table/components/yeti-table/tfoot/template.hbs"
  });
  _exports.default = _default;
});