define('ember-inline-edit/components/ember-inline-edit', ['exports', 'ember-inline-edit/templates/components/ember-inline-edit'], function (exports, _emberInlineEdit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _emberInlineEdit.default,
    classNames: ['ember-inline-edit'],
    classNameBindings: ['isEditing:is-editing', 'enabled::disabled'],

    isEditing: false,
    isNotEditing: Ember.computed.not('isEditing'),

    enabled: true,
    field: 'text',

    value: null,
    previousValue: null,

    placeholder: 'Not Provided',
    saveLabel: 'Save',
    cancelLabel: 'Cancel',
    editLabel: 'Edit',

    fieldWidth: null,

    showSaveButton: true,
    showCancelButton: true,

    editorClass: '',
    buttonContainerClass: '',
    editButtonClass: '',
    saveButtonClass: '',
    cancelButtonClass: '',

    didInsertElement() {
      this._super(...arguments);

      this._handleClicks = this._handleClicks.bind(this);
      document.addEventListener('click', this._handleClicks);
    },

    willDestroyElement() {
      document.removeEventListener('click', this._handleClicks);
    },

    _handleClicks(ev) {
      if (!this.enabled) return;

      let { isEditing } = this;
      let clickedInside = this.element.contains(ev.target);

      if (clickedInside && !isEditing) {
        if (this.showEditButton) {
          return;
        }

        this._setFieldWidth();
        this.send('startEditing', ev);
      } else if (!clickedInside && isEditing) {
        if (this.onOutsideClick) {
          this.onOutsideClick(this.value) && Ember.set(this, 'isEditing', false);
        } else {
          this.send('cancel');
        }
      }
    },

    _setFieldWidth() {
      const { width } = this.element.getBoundingClientRect();

      Ember.run(this, () => {
        Ember.set(this, 'fieldWidth', Ember.String.htmlSafe(`width: ${width + 2}px`));
      });
    },

    didReceiveAttrs() {
      if (this.enabled === false && this.isEditing) {
        this.send('cancel');
      }
    },

    actions: {
      save() {
        Ember.tryInvoke(this, 'onSave', [this.value]);

        Ember.run(this, () => {
          Ember.set(this, 'isEditing', false);
        });
      },

      startEditing(e) {
        e.stopPropagation();
        Ember.tryInvoke(this, 'onEdit');

        Ember.run(this, () => {
          Ember.set(this, 'previousValue', this.value);
          Ember.set(this, 'isEditing', true);
        });
      },

      cancel() {
        Ember.tryInvoke(this, 'onCancel');

        Ember.run(this, () => {
          Ember.set(this, 'value', this.previousValue);
          Ember.set(this, 'isEditing', false);
        });
      }
    }
  });
});