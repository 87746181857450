define("ember-power-calendar/helpers/power-calendar-format-date", ["exports", "ember-power-calendar-utils"], function (_exports, _emberPowerCalendarUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.powerCalendarFormatDate = powerCalendarFormatDate;
  function powerCalendarFormatDate(_ref, _ref2) {
    let [date, format] = _ref;
    let {
      locale
    } = _ref2;
    return (0, _emberPowerCalendarUtils.formatDate)(date, format, locale);
  }
  var _default = Ember.Helper.helper(powerCalendarFormatDate);
  _exports.default = _default;
});