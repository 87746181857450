define("@glimmer/opcode-compiler", ["exports", "@glimmer/vm", "@glimmer/util", "@glimmer/program", "@glimmer/encoder"], function (_exports, _vm, _util, _program, _encoder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.WrappedBuilder = _exports.UNHANDLED = _exports.StdLib = _exports.PartialDefinitionImpl = _exports.NONE = _exports.MacrosImpl = _exports.MINIMAL_CAPABILITIES = _exports.EMPTY_BLOCKS = _exports.DEFAULT_CAPABILITIES = _exports.CompileTimeCompilationContextImpl = void 0;
  _exports.compilable = compilable;
  _exports.compileStatements = compileStatements;
  _exports.compileStd = compileStd;
  _exports.debugCompiler = void 0;
  _exports.invokeStaticBlock = InvokeStaticBlock;
  _exports.invokeStaticBlockWithStack = InvokeStaticBlockWithStack;
  _exports.meta = meta;
  _exports.resolveLayoutForTag = resolveLayoutForTag;
  _exports.staticComponent = StaticComponent;
  _exports.syntaxCompilationContext = syntaxCompilationContext;
  _exports.templateCacheCounters = void 0;
  _exports.templateCompilationContext = templateCompilationContext;
  _exports.templateFactory = templateFactory;
  function arr(value) {
    return {
      type: 'array',
      value
    };
  }
  function strArray(value) {
    return {
      type: 'string-array',
      value
    };
  }
  function serializable(value) {
    return {
      type: 'serializable',
      value
    };
  }
  function other(value) {
    return {
      type: 'other',
      value
    };
  }
  function label(value) {
    return {
      type: 'label',
      value
    };
  }
  function immediate(value) {
    return {
      type: 'immediate',
      value
    };
  }
  function prim(value) {
    return {
      type: 'primitive',
      value
    };
  }
  var DEFAULT_CAPABILITIES = {
    dynamicLayout: true,
    dynamicTag: true,
    prepareArgs: true,
    createArgs: true,
    attributeHook: false,
    elementHook: false,
    dynamicScope: true,
    createCaller: false,
    updateHook: true,
    createInstance: true,
    wrapped: false,
    willDestroy: false
  };
  _exports.DEFAULT_CAPABILITIES = DEFAULT_CAPABILITIES;
  var MINIMAL_CAPABILITIES = {
    dynamicLayout: false,
    dynamicTag: false,
    prepareArgs: false,
    createArgs: false,
    attributeHook: false,
    elementHook: false,
    dynamicScope: false,
    createCaller: false,
    updateHook: false,
    createInstance: false,
    wrapped: false,
    willDestroy: false
  };
  _exports.MINIMAL_CAPABILITIES = MINIMAL_CAPABILITIES;
  function resolveLayoutForTag(tag, _ref) {
    var {
      resolver,
      meta: {
        owner
      }
    } = _ref;
    var component = resolver.lookupComponent(tag, owner);
    if (component === null) return component;
    var {
      handle,
      compilable,
      capabilities
    } = component;
    return {
      handle,
      compilable,
      capabilities: capabilities || MINIMAL_CAPABILITIES
    };
  }
  /**
   * Push a reference onto the stack corresponding to a statically known primitive
   * @param value A JavaScript primitive (undefined, null, boolean, number or string)
   */

  function PushPrimitiveReference(value) {
    return [PushPrimitive(value), op(30
    /* PrimitiveReference */)];
  }
  /**
   * Push an encoded representation of a JavaScript primitive on the stack
   *
   * @param value A JavaScript primitive (undefined, null, boolean, number or string)
   */

  function PushPrimitive(primitive) {
    var p = typeof primitive === 'number' && (0, _util.isSmallInt)(primitive) ? immediate(primitive) : prim(primitive);
    return op(29
    /* Primitive */, p);
  }
  /**
   * Invoke a foreign function (a "helper") based on a statically known handle
   *
   * @param compile.handle A handle
   * @param compile.params An optional list of expressions to compile
   * @param compile.hash An optional list of named arguments (name + expression) to compile
   */

  function Call(_ref2) {
    var {
      handle,
      params,
      hash
    } = _ref2;
    return [op(0
    /* PushFrame */), op('SimpleArgs', {
      params,
      hash,
      atNames: false
    }), op(16
    /* Helper */, handle), op(1
    /* PopFrame */), op(35
    /* Fetch */, _vm.$v0)];
  }
  /**
   * Evaluate statements in the context of new dynamic scope entries. Move entries from the
   * stack into named entries in the dynamic scope, then evaluate the statements, then pop
   * the dynamic scope
   *
   * @param names a list of dynamic scope names
   * @param block a function that returns a list of statements to evaluate
   */

  function DynamicScope(names, block) {
    return [op(58
    /* PushDynamicScope */), op(57
    /* BindDynamicScope */, strArray(names)), block(), op(59
    /* PopDynamicScope */)];
  }
  /**
   * Yield to a block located at a particular symbol location.
   *
   * @param to the symbol containing the block to yield to
   * @param params optional block parameters to yield to the block
   */

  function YieldBlock(to, params) {
    return [op('SimpleArgs', {
      params,
      hash: null,
      atNames: true
    }), op(23
    /* GetBlock */, to), op(24
    /* SpreadBlock */), op('Option', op(60
    /* CompileBlock */)), op(63
    /* InvokeYield */), op(39
    /* PopScope */), op(1
    /* PopFrame */)];
  }
  /**
   * Push an (optional) yieldable block onto the stack. The yieldable block must be known
   * statically at compile time.
   *
   * @param block An optional Compilable block
   */

  function PushYieldableBlock(block) {
    return [PushSymbolTable(block && block.symbolTable), op(61
    /* PushBlockScope */), PushCompilable(block)];
  }
  /**
   * Invoke a block that is known statically at compile time.
   *
   * @param block a Compilable block
   */

  function InvokeStaticBlock(block) {
    return [op(0
    /* PushFrame */), PushCompilable(block), op(60
    /* CompileBlock */), op(2
    /* InvokeVirtual */), op(1
    /* PopFrame */)];
  }
  /**
   * Invoke a static block, preserving some number of stack entries for use in
   * updating.
   *
   * @param block A compilable block
   * @param callerCount A number of stack entries to preserve
   */

  function InvokeStaticBlockWithStack(block, callerCount) {
    var {
      parameters
    } = block.symbolTable;
    var calleeCount = parameters.length;
    var count = Math.min(callerCount, calleeCount);
    if (count === 0) {
      return InvokeStaticBlock(block);
    }
    var out = [];
    out.push(op(0
    /* PushFrame */));

    if (count) {
      out.push(op(38
      /* ChildScope */));

      for (var i = 0; i < count; i++) {
        out.push(op(32
        /* Dup */, _vm.$fp, callerCount - i));
        out.push(op(19
        /* SetVariable */, parameters[i]));
      }
    }
    out.push(PushCompilable(block));
    out.push(op(60
    /* CompileBlock */));

    out.push(op(2
    /* InvokeVirtual */));

    if (count) {
      out.push(op(39
      /* PopScope */));
    }

    out.push(op(1
    /* PopFrame */));

    return out;
  }
  function PushSymbolTable(table) {
    if (table) {
      return op(62
      /* PushSymbolTable */, serializable(table));
    } else {
      return PushPrimitive(null);
    }
  }
  function PushCompilable(block) {
    if (block === null) {
      return PushPrimitive(null);
    } else {
      return op(28
      /* Constant */, other(block));
    }
  }
  function ContentTypeSwitchCases(callback) {
    // Setup the switch DSL
    var clauses = [];
    var count = 0;
    function when(match, callback) {
      clauses.push({
        match,
        callback,
        label: `CLAUSE${count++}`
      });
    } // Call the callback

    callback(when); // Emit the opcodes for the switch

    var out = [op(68
    /* Enter */, 1), op(75
    /* ContentType */), op('StartLabels')]; // First, emit the jump opcodes. We don't need a jump for the last
    // opcode, since it bleeds directly into its clause.

    for (var clause of clauses.slice(0, -1)) {
      out.push(op(66
      /* JumpEq */, label(clause.label), clause.match));
    } // Enumerate the clauses in reverse order. Earlier matches will
    // require fewer checks.

    for (var i = clauses.length - 1; i >= 0; i--) {
      var _clause = clauses[i];
      out.push(op('Label', _clause.label), op(33
      /* Pop */, 1), _clause.callback()); // The first match is special: it is placed directly before the END
      // label, so no additional jump is needed at the end of it.

      if (i !== 0) {
        out.push(op(4
        /* Jump */, label('END')));
      }
    }
    out.push(op('Label', 'END'), op('StopLabels'), op(69
    /* Exit */));

    return out;
  }
  /**
   * A convenience for pushing some arguments on the stack and
   * running some code if the code needs to be re-executed during
   * updating execution if some of the arguments have changed.
   *
   * # Initial Execution
   *
   * The `args` function should push zero or more arguments onto
   * the stack and return the number of arguments pushed.
   *
   * The `body` function provides the instructions to execute both
   * during initial execution and during updating execution.
   *
   * Internally, this function starts by pushing a new frame, so
   * that the body can return and sets the return point ($ra) to
   * the ENDINITIAL label.
   *
   * It then executes the `args` function, which adds instructions
   * responsible for pushing the arguments for the block to the
   * stack. These arguments will be restored to the stack before
   * updating execution.
   *
   * Next, it adds the Enter opcode, which marks the current position
   * in the DOM, and remembers the current $pc (the next instruction)
   * as the first instruction to execute during updating execution.
   *
   * Next, it runs `body`, which adds the opcodes that should
   * execute both during initial execution and during updating execution.
   * If the `body` wishes to finish early, it should Jump to the
   * `FINALLY` label.
   *
   * Next, it adds the FINALLY label, followed by:
   *
   * - the Exit opcode, which finalizes the marked DOM started by the
   *   Enter opcode.
   * - the Return opcode, which returns to the current return point
   *   ($ra).
   *
   * Finally, it adds the ENDINITIAL label followed by the PopFrame
   * instruction, which restores $fp, $sp and $ra.
   *
   * # Updating Execution
   *
   * Updating execution for this `replayable` occurs if the `body` added an
   * assertion, via one of the `JumpIf`, `JumpUnless` or `AssertSame` opcodes.
   *
   * If, during updating executon, the assertion fails, the initial VM is
   * restored, and the stored arguments are pushed onto the stack. The DOM
   * between the starting and ending markers is cleared, and the VM's cursor
   * is set to the area just cleared.
   *
   * The return point ($ra) is set to -1, the exit instruction.
   *
   * Finally, the $pc is set to to the instruction saved off by the
   * Enter opcode during initial execution, and execution proceeds as
   * usual.
   *
   * The only difference is that when a `Return` instruction is
   * encountered, the program jumps to -1 rather than the END label,
   * and the PopFrame opcode is not needed.
   */

  function Replayable(_ref3) {
    var {
      args,
      body
    } = _ref3;
    // Push the arguments onto the stack. The args() function
    // tells us how many stack elements to retain for re-execution
    // when updating.
    var {
      count,
      actions
    } = args(); // Start a new label frame, to give END and RETURN
    // a unique meaning.

    return [op('StartLabels'), op(0
    /* PushFrame */),
    // If the body invokes a block, its return will return to
    // END. Otherwise, the return in RETURN will return to END.
    op(6
    /* ReturnTo */, label('ENDINITIAL')), actions,
    // Start a new updating closure, remembering `count` elements
    // from the stack. Everything after this point, and before END,
    // will execute both initially and to update the block.
    //
    // The enter and exit opcodes also track the area of the DOM
    // associated with this block. If an assertion inside the block
    // fails (for example, the test value changes from true to false
    // in an #if), the DOM is cleared and the program is re-executed,
    // restoring `count` elements to the stack and executing the
    // instructions between the enter and exit.
    op(68
    /* Enter */, count),
    // Evaluate the body of the block. The body of the block may
    // return, which will jump execution to END during initial
    // execution, and exit the updating routine.
    body(),
    // All execution paths in the body should run the FINALLY once
    // they are done. It is executed both during initial execution
    // and during updating execution.
    op('Label', 'FINALLY'),
    // Finalize the DOM.
    op(69
    /* Exit */),
    // In initial execution, this is a noop: it returns to the
    // immediately following opcode. In updating execution, this
    // exits the updating routine.
    op(5
    /* Return */),
    // Cleanup code for the block. Runs on initial execution
    // but not on updating.
    op('Label', 'ENDINITIAL'), op(1
    /* PopFrame */), op('StopLabels')];
  }
  /**
   * A specialized version of the `replayable` convenience that allows the
   * caller to provide different code based upon whether the item at
   * the top of the stack is true or false.
   *
   * As in `replayable`, the `ifTrue` and `ifFalse` code can invoke `return`.
   *
   * During the initial execution, a `return` will continue execution
   * in the cleanup code, which finalizes the current DOM block and pops
   * the current frame.
   *
   * During the updating execution, a `return` will exit the updating
   * routine, as it can reuse the DOM block and is always only a single
   * frame deep.
   */

  function ReplayableIf(_ref4) {
    var {
      args,
      ifTrue,
      ifFalse
    } = _ref4;
    return Replayable({
      args,
      body: () => {
        var out = [
        // If the conditional is false, jump to the ELSE label.
        op(65
        /* JumpUnless */, label('ELSE')),
        // Otherwise, execute the code associated with the true branch.
        ifTrue(),
        // We're done, so return. In the initial execution, this runs
        // the cleanup code. In the updating VM, it exits the updating
        // routine.
        op(4
        /* Jump */, label('FINALLY')), op('Label', 'ELSE')]; // If the conditional is false, and code associatied ith the
        // false branch was provided, execute it. If there was no code
        // associated with the false branch, jumping to the else statement
        // has no other behavior.

        if (ifFalse) {
          out.push(ifFalse());
        }
        return out;
      }
    });
  }
  function pushBuilderOp(context, op) {
    var {
      encoder
    } = context;
    switch (op.op) {
      case "Option"
      /* Option */:
        return concat(context, option$1(op));
      case "Label"
      /* Label */:
        return encoder.label(op.op1);
      case "StartLabels"
      /* StartLabels */:
        return encoder.startLabels();
      case "StopLabels"
      /* StopLabels */:
        return encoder.stopLabels();
      default:
        return (0, _util.exhausted)(op);
    }
  }
  function option$1(op) {
    var value = op.op1;
    return value === null ? NONE : value;
  }
  function pushCompileOp(context, action) {
    concatStatements(context, compileOp(context, action));
  }
  function compileOp(context, action) {
    switch (action.op) {
      case "CompileBlock"
      /* CompileBlock */:
        return CompileBlockOp(context, action);
      case "CompileInline"
      /* CompileInline */:
        return CompileInlineOp(context, action);
      case "DynamicComponent"
      /* DynamicComponent */:
        return DynamicComponent(context, action);
      case "IfResolvedComponent"
      /* IfResolvedComponent */:
        return IfResolvedComponent(context, action);
      default:
        return (0, _util.exhausted)(action);
    }
  }
  function CompileBlockOp(context, op) {
    return compileBlock(op.op1, context);
  }
  function CompileInlineOp(context, op) {
    var {
      inline,
      ifUnhandled
    } = op.op1;
    var returned = compileInline(inline, context);
    if (isHandled(returned)) {
      return returned;
    } else {
      return ifUnhandled(inline);
    }
  }
  function DynamicComponent(context, action) {
    var {
      definition,
      attrs,
      params,
      args,
      blocks,
      atNames,
      curried
    } = action.op1;
    var attrsBlock = attrs && attrs.length > 0 ? compilableBlock(attrs, context.meta) : null;
    var compiled = Array.isArray(blocks) || blocks === null ? namedBlocks(blocks, context.meta) : blocks;
    return InvokeDynamicComponent(context.meta, {
      definition,
      attrs: attrsBlock,
      params,
      hash: args,
      atNames,
      blocks: compiled,
      curried
    });
  }
  function IfResolvedComponent(context, action) {
    var {
      name,
      attrs,
      blocks,
      staticTemplate,
      dynamicTemplate,
      orElse
    } = action.op1;
    var component = resolveLayoutForTag(name, {
      resolver: context.syntax.program.resolver,
      meta: context.meta
    });
    var {
      meta
    } = context;
    if (component !== null) {
      var {
        handle,
        capabilities,
        compilable: compilable$$1
      } = component;
      var attrsBlock = compilableBlock(attrs, meta);
      var compilableBlocks = namedBlocks(blocks, meta);
      if (compilable$$1 !== null) {
        return staticTemplate(handle, capabilities, compilable$$1, {
          attrs: attrsBlock,
          blocks: compilableBlocks
        });
      } else {
        return dynamicTemplate(handle, capabilities, {
          attrs: attrsBlock,
          blocks: compilableBlocks
        });
      }
    } else if (orElse) {
      return orElse();
    } else {
      throw new Error(`Compile Error: Cannot find component ${name}`);
    }
  }
  function pushOp(encoder, constants, op) {
    if (op.op3 !== undefined) {
      encoder.push(constants, op.op, op.op1, op.op2, op.op3);
    } else if (op.op2 !== undefined) {
      encoder.push(constants, op.op, op.op1, op.op2);
    } else if (op.op1 !== undefined) {
      encoder.push(constants, op.op, op.op1);
    } else {
      encoder.push(constants, op.op);
    }
  }
  class Compilers {
    constructor() {
      this.names = {};
      this.funcs = [];
    }
    add(name, func) {
      this.names[name] = this.funcs.push(func) - 1;
    }
    compile(sexp, meta) {
      var name = sexp[0];
      var index = this.names[name];
      var func = this.funcs[index];
      return func(sexp, meta);
    }
  }
  var EXPRESSIONS = new Compilers();
  EXPRESSIONS.add(31
  /* Concat */, _ref5 => {
    var [, parts] = _ref5;
    var out = [];
    for (var part of parts) {
      out.push(op('Expr', part));
    }
    out.push(op(27
    /* Concat */, parts.length));
    return out;
  });
  EXPRESSIONS.add(30
  /* Call */, (_ref6, meta) => {
    var [, name, params, hash] = _ref6;
    // TODO: triage this in the WF compiler
    var start = 0;
    var offset = 0;
    if (isComponent(name, meta)) {
      if (!params || params.length === 0) {
        return op('Error', {
          problem: 'component helper requires at least one argument',
          start: start,
          end: start + offset
        });
      }
      var [definition, ...restArgs] = params;
      return curryComponent({
        definition,
        params: restArgs,
        hash,
        atNames: false
      }, meta.owner);
    }
    var nameOrError = expectString(name, meta, 'Expected call head to be a string');
    if (typeof nameOrError !== 'string') {
      return nameOrError;
    }
    return op('IfResolved', {
      kind: "Helper"
      /* Helper */,

      name: nameOrError,
      andThen: handle => Call({
        handle,
        params,
        hash
      }),
      span: {
        start,
        end: start + offset
      }
    });
  });
  function isGetContextualFree(opcode) {
    return opcode[0] >= 34
    /* GetContextualFreeStart */;
  }

  function isComponent(expr, meta) {
    if (!Array.isArray(expr)) {
      return false;
    }
    if (isGetContextualFree(expr)) {
      var head = expr[1];
      if (meta.upvars && meta.upvars[head] === 'component') {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
  EXPRESSIONS.add(32
  /* GetSymbol */, _ref7 => {
    var [, sym, path] = _ref7;
    return withPath(op(21
    /* GetVariable */, sym), path);
  });
  EXPRESSIONS.add(33
  /* GetFree */, _ref8 => {
    var [, sym, path] = _ref8;
    return withPath(op('ResolveFree', sym), path);
  });
  EXPRESSIONS.add(34
  /* GetFreeInAppendSingleId */, _ref9 => {
    var [, sym, path] = _ref9;
    return withPath(op('ResolveContextualFree', {
      freeVar: sym,
      context: 0
      /* AppendSingleId */
    }), path);
  });
  EXPRESSIONS.add(35
  /* GetFreeInExpression */, _ref10 => {
    var [, sym, path] = _ref10;
    return withPath(op('ResolveContextualFree', {
      freeVar: sym,
      context: 1
      /* Expression */
    }), path);
  });
  EXPRESSIONS.add(36
  /* GetFreeInCallHead */, _ref11 => {
    var [, sym, path] = _ref11;
    return withPath(op('ResolveContextualFree', {
      freeVar: sym,
      context: 2
      /* CallHead */
    }), path);
  });
  EXPRESSIONS.add(37
  /* GetFreeInBlockHead */, _ref12 => {
    var [, sym, path] = _ref12;
    return withPath(op('ResolveContextualFree', {
      freeVar: sym,
      context: 3
      /* BlockHead */
    }), path);
  });
  EXPRESSIONS.add(38
  /* GetFreeInModifierHead */, _ref13 => {
    var [, sym, path] = _ref13;
    return withPath(op('ResolveContextualFree', {
      freeVar: sym,
      context: 4
      /* ModifierHead */
    }), path);
  });
  EXPRESSIONS.add(39
  /* GetFreeInComponentHead */, _ref14 => {
    var [, sym, path] = _ref14;
    return withPath(op('ResolveContextualFree', {
      freeVar: sym,
      context: 5
      /* ComponentHead */
    }), path);
  });
  function withPath(expr, path) {
    if (path === undefined || path.length === 0) return expr;
    if (!Array.isArray(expr)) expr = [expr];
    for (var i = 0; i < path.length; i++) {
      expr.push(op(22
      /* GetProperty */, path[i]));
    }
    return expr;
  }
  EXPRESSIONS.add(29
  /* Undefined */, () => PushPrimitiveReference(undefined));
  EXPRESSIONS.add(27
  /* HasBlock */, _ref15 => {
    var [, block] = _ref15;
    return [op('Expr', block), op(25
    /* HasBlock */)];
  });

  EXPRESSIONS.add(28
  /* HasBlockParams */, _ref16 => {
    var [, block] = _ref16;
    return [op('Expr', block), op(24
    /* SpreadBlock */), op(60
    /* CompileBlock */), op(26
    /* HasBlockParams */)];
  });

  function pushResolutionOp(encoder, context, operation, constants) {
    switch (operation.op) {
      case "SimpleArgs"
      /* SimpleArgs */:
        concatExpressions(encoder, context, compileSimpleArgs(operation.op1.params, operation.op1.hash, operation.op1.atNames), constants);
        break;
      case "Expr"
      /* Expr */:
        concatExpressions(encoder, context, expr(operation.op1, context.meta), constants);
        break;
      case "IfResolved"
      /* IfResolved */:
        {
          concatExpressions(encoder, context, ifResolved(context, operation), constants);
          break;
        }
      case "ResolveFree"
      /* ResolveFree */:
        {
          throw new Error('Unimplemented HighLevelResolutionOpcode.ResolveFree');
        }
      case "ResolveContextualFree"
      /* ResolveContextualFree */:
        {
          var {
            freeVar,
            context: expressionContext
          } = operation.op1;
          if (context.meta.asPartial) {
            var name = context.meta.upvars[freeVar];
            concatExpressions(encoder, context, [op(102
            /* ResolveMaybeLocal */, name)], constants);
            break;
          }
          switch (expressionContext) {
            case 1
            /* Expression */:
              {
                // in classic mode, this is always a this-fallback
                var _name2 = context.meta.upvars[freeVar];
                concatExpressions(encoder, context, [op(21
                /* GetVariable */, 0), op(22
                /* GetProperty */, _name2)], constants);
                break;
              }
            case 0
            /* AppendSingleId */:
              {
                var resolver = context.syntax.program.resolver;
                var _name3 = context.meta.upvars[freeVar];
                var resolvedHelper = resolver.lookupHelper(_name3, context.meta.owner);
                var expressions;
                if (resolvedHelper) {
                  expressions = Call({
                    handle: resolvedHelper,
                    params: null,
                    hash: null
                  });
                } else {
                  // in classic mode, this is always a this-fallback
                  expressions = [op(21
                  /* GetVariable */, 0), op(22
                  /* GetProperty */, _name3)];
                }
                concatExpressions(encoder, context, expressions, constants);
                break;
              }
            default:
              throw new Error(`unimplemented: Can't evaluate expression in context ${expressionContext}`);
          }
          break;
        }
      default:
        return (0, _util.exhausted)(operation);
    }
  }
  function expr(expression$$1, meta$$1) {
    if (Array.isArray(expression$$1)) {
      return EXPRESSIONS.compile(expression$$1, meta$$1);
    } else {
      return [PushPrimitive(expression$$1), op(30
      /* PrimitiveReference */)];
    }
  }

  function compileSimpleArgs(params, hash, atNames) {
    var out = [];
    var {
      count,
      actions
    } = CompilePositional(params);
    out.push(actions);
    var flags = count << 4;
    if (atNames) flags |= 0b1000;
    var names = _util.EMPTY_ARRAY;
    if (hash) {
      names = hash[0];
      var val = hash[1];
      for (var i = 0; i < val.length; i++) {
        out.push(op('Expr', val[i]));
      }
    }
    out.push(op(82
    /* PushArgs */, strArray(names), strArray(_util.EMPTY_ARRAY), flags));
    return out;
  }
  function ifResolved(context, _ref17) {
    var {
      op1
    } = _ref17;
    var {
      kind,
      name,
      andThen,
      orElse,
      span
    } = op1;
    var resolved = resolve(context.syntax.program.resolver, kind, name, context.meta.owner);
    if (resolved !== null) {
      return andThen(resolved);
    } else if (orElse) {
      return orElse();
    } else {
      return error(`Unexpected ${kind} ${name}`, span.start, span.end);
    }
  }
  function resolve(resolver, kind, name, owner) {
    switch (kind) {
      case "Modifier"
      /* Modifier */:
        return resolver.lookupModifier(name, owner);
      case "Helper"
      /* Helper */:
        return resolver.lookupHelper(name, owner);
      case "ComponentDefinition"
      /* ComponentDefinition */:
        {
          var component = resolver.lookupComponent(name, owner);
          return component && component.handle;
        }
    }
  }
  var NONE = {
    'no-action': true
  };
  _exports.NONE = NONE;
  var UNHANDLED = {
    'not-handled': true
  };
  _exports.UNHANDLED = UNHANDLED;
  function isNoAction(actions) {
    return actions && !!actions['no-action'];
  }
  function isHandled(actions) {
    return !actions || !actions['not-handled'];
  }
  function concat(context, action) {
    if (isNoAction(action)) {
      return;
    } else if (Array.isArray(action)) {
      for (var item of action) {
        concat(context, item);
      }
    } else if (action.type === 'Simple') {
      pushBuilderOp(context, action);
    } else {
      pushOp(context.encoder, context.syntax.program.constants, action);
    }
  }
  function concatExpressions(encoder, context, action, constants) {
    if (isNoAction(action)) {
      return;
    } else if (Array.isArray(action)) {
      for (var item of action) {
        concatExpressions(encoder, context, item, constants);
      }
    } else if (action.type === 'Number') {
      pushOp(encoder, constants, action);
    } else if (action.type === 'Resolution') {
      pushResolutionOp(encoder, context, action, constants);
    } else if (action.type === 'Simple') {
      pushBuilderOp(context, action);
    } else if (action.type === 'Error') {
      encoder.error({
        problem: action.op1.problem,
        span: {
          start: action.op1.start,
          end: action.op1.end
        }
      });
    } else {
      throw (0, _util.assertNever)(action, 'unexpected action kind');
    }
  }
  function concatStatements(context, action) {
    if (isNoAction(action)) {
      return;
    } else if (Array.isArray(action)) {
      for (var item of action) {
        concatStatements(context, item);
      }
    } else if (action.type === 'Number') {
      pushOp(context.encoder, context.syntax.program.constants, action);
    } else {
      if (action.type === 'Compile') {
        pushCompileOp(context, action);
      } else if (action.type === 'Resolution') {
        pushResolutionOp(context.encoder, context, action, context.syntax.program.constants);
      } else if (action.type === 'Simple') {
        pushBuilderOp(context, action);
      } else if (action.type === 'Error') {} else {
        throw (0, _util.assertNever)(action, `unexpected action type`);
      }
    }
  }
  function populateBuiltins(blocks, inlines) {
    blocks.add('if', (params, _hash, blocks) => {
      if (!params || params.length !== 1) {
        throw new Error(`SYNTAX ERROR: #if requires a single argument`);
      }
      return ReplayableIf({
        args() {
          return {
            count: 1,
            actions: [op('Expr', params[0]), op(70
            /* ToBoolean */)]
          };
        },

        ifTrue() {
          return InvokeStaticBlock(blocks.get('default'));
        },
        ifFalse() {
          if (blocks.has('else')) {
            return InvokeStaticBlock(blocks.get('else'));
          } else {
            return NONE;
          }
        }
      });
    });
    blocks.add('unless', (params, _hash, blocks) => {
      if (!params || params.length !== 1) {
        throw new Error(`SYNTAX ERROR: #unless requires a single argument`);
      }
      return ReplayableIf({
        args() {
          return {
            count: 1,
            actions: [op('Expr', params[0]), op(70
            /* ToBoolean */)]
          };
        },

        ifTrue() {
          if (blocks.has('else')) {
            return InvokeStaticBlock(blocks.get('else'));
          } else {
            return NONE;
          }
        },
        ifFalse() {
          return InvokeStaticBlock(blocks.get('default'));
        }
      });
    });
    blocks.add('with', (params, _hash, blocks) => {
      if (!params || params.length !== 1) {
        throw new Error(`SYNTAX ERROR: #with requires a single argument`);
      }
      return ReplayableIf({
        args() {
          return {
            count: 2,
            actions: [op('Expr', params[0]), op(32
            /* Dup */, _vm.$sp, 0), op(70
            /* ToBoolean */)]
          };
        },

        ifTrue() {
          return InvokeStaticBlockWithStack(blocks.get('default'), 1);
        },
        ifFalse() {
          if (blocks.has('else')) {
            return InvokeStaticBlock(blocks.get('else'));
          } else {
            return NONE;
          }
        }
      });
    });
    blocks.add('let', (params, _hash, blocks) => {
      if (!params) {
        return error('let requires arguments', 0, 0);
      }
      var {
        count,
        actions
      } = CompilePositional(params);
      return [actions, InvokeStaticBlockWithStack(blocks.get('default'), count)];
    });
    blocks.add('each', (params, hash, blocks) => {
      return Replayable({
        args() {
          var actions;
          if (hash && hash[0][0] === 'key') {
            actions = [op('Expr', hash[1][0])];
          } else {
            actions = [PushPrimitiveReference(null)];
          }
          actions.push(op('Expr', params[0]));
          return {
            count: 2,
            actions
          };
        },
        body() {
          var out = [op(71
          /* EnterList */, label('BODY'), label('ELSE')), op(0
          /* PushFrame */), op(32
          /* Dup */, _vm.$fp, 1), op(6
          /* ReturnTo */, label('ITER')), op('Label', 'ITER'), op(73
          /* Iterate */, label('BREAK')), op('Label', 'BODY'), InvokeStaticBlockWithStack(blocks.get('default'), 2), op(33
          /* Pop */, 2), op(4
          /* Jump */, label('FINALLY')), op('Label', 'BREAK'), op(1
          /* PopFrame */), op(72
          /* ExitList */), op(4
          /* Jump */, label('FINALLY')), op('Label', 'ELSE')];
          if (blocks.has('else')) {
            out.push(InvokeStaticBlock(blocks.get('else')));
          }
          return out;
        }
      });
    });
    blocks.add('in-element', (params, hash, blocks) => {
      if (!params || params.length !== 1) {
        throw new Error(`SYNTAX ERROR: #in-element requires a single argument`);
      }
      return ReplayableIf({
        args() {
          var [keys, values] = hash;
          var actions = [];
          for (var i = 0; i < keys.length; i++) {
            var key = keys[i];
            if (key === 'guid' || key === 'insertBefore') {
              actions.push(op('Expr', values[i]));
            } else {
              throw new Error(`SYNTAX ERROR: #in-element does not take a \`${keys[0]}\` option`);
            }
          }
          actions.push(op('Expr', params[0]), op(32
          /* Dup */, _vm.$sp, 0));
          return {
            count: 4,
            actions
          };
        },
        ifTrue() {
          return [op(49
          /* PushRemoteElement */), InvokeStaticBlock(blocks.get('default')), op(55
          /* PopRemoteElement */)];
        }
      });
    });

    blocks.add('-with-dynamic-vars', (_params, hash, blocks) => {
      if (hash) {
        var [names, expressions] = hash;
        var {
          actions
        } = CompilePositional(expressions);
        return [actions, DynamicScope(names, () => {
          return InvokeStaticBlock(blocks.get('default'));
        })];
      } else {
        return InvokeStaticBlock(blocks.get('default'));
      }
    });
    blocks.add('component', (_params, hash, blocks, context) => {
      var tag = _params[0];
      if (typeof tag === 'string') {
        var returned = StaticComponentHelper(context, _params[0], hash, blocks.get('default'));
        if (isHandled(returned)) return returned;
      }
      var [definition, ...params] = _params;
      return op('DynamicComponent', {
        definition,
        attrs: null,
        params,
        args: hash,
        atNames: false,
        blocks,
        curried: false
      });
    });
    inlines.add('component', (_name, _params, hash, context) => {
      var tag = _params && _params[0];
      if (typeof tag === 'string') {
        var returned = StaticComponentHelper(context, tag, hash, null);
        if (returned !== UNHANDLED) return returned;
      }
      var [definition, ...params] = _params;
      return InvokeDynamicComponent(context.meta, {
        definition,
        attrs: null,
        params,
        hash,
        atNames: false,
        blocks: EMPTY_BLOCKS,
        curried: false
      });
    });
    return {
      blocks,
      inlines
    };
  }
  class MacrosImpl {
    constructor() {
      var {
        blocks,
        inlines
      } = populateBuiltins(new Blocks(), new Inlines());
      this.blocks = blocks;
      this.inlines = inlines;
    }
  }
  _exports.MacrosImpl = MacrosImpl;
  class Blocks {
    constructor() {
      this.names = (0, _util.dict)();
      this.funcs = [];
    }
    add(name, func) {
      this.funcs.push(func);
      this.names[name] = this.funcs.length - 1;
    }
    addMissing(func) {
      this.missing = func;
    }
    compile(name, params, hash, blocks, context) {
      var index = this.names[name];
      var macroContext = {
        resolver: context.syntax.program.resolver,
        meta: context.meta
      };
      if (index === undefined) {
        var func = this.missing;
        var handled = func(name, params, hash, blocks, macroContext);
        return handled;
      } else {
        var _func = this.funcs[index];
        return _func(params, hash, blocks, macroContext);
      }
    }
  }
  class Inlines {
    constructor() {
      this.names = (0, _util.dict)();
      this.funcs = [];
    }
    add(name, func) {
      this.funcs.push(func);
      this.names[name] = this.funcs.length - 1;
    }
    addMissing(func) {
      this.missing = func;
    }
    compile(sexp, context) {
      var [, value] = sexp; // TODO: Fix this so that expression macros can return
      // things like components, so that {{component foo}}
      // is the same as {{(component foo)}}

      if (!Array.isArray(value)) return UNHANDLED;
      var name;
      var params;
      var hash;
      if (value[0] === 30
      /* Call */) {
        var nameOrError = expectString(value[1], context.meta, 'Expected head of call to be a string');
        if (typeof nameOrError !== 'string') {
          return nameOrError;
        }
        name = nameOrError;
        params = value[2];
        hash = value[3];
      } else if (isGet(value)) {
        var pathName = simplePathName(value, context.meta);
        if (pathName === null) {
          return UNHANDLED;
        }
        name = pathName;
        params = null;
        hash = null;
      } else {
        return UNHANDLED;
      }
      var index = this.names[name];
      var macroContext = {
        resolver: context.syntax.program.resolver,
        meta: context.meta
      };
      if (index === undefined && this.missing) {
        var func = this.missing;
        return func(name, params, hash, macroContext);
      } else if (index !== undefined) {
        var _func2 = this.funcs[index];
        return _func2(name, params, hash, macroContext);
      } else {
        return UNHANDLED;
      }
    }
  }
  function syntaxCompilationContext(artifacts, resolver, macros) {
    if (macros === void 0) {
      macros = new MacrosImpl();
    }
    return {
      program: new CompileTimeCompilationContextImpl(artifacts, resolver),
      macros
    };
  }
  function templateCompilationContext(syntax, meta) {
    var encoder = new EncoderImpl();
    return {
      syntax,
      encoder,
      meta
    };
  }
  var STATEMENTS = new Compilers();
  var INFLATE_ATTR_TABLE = ['class', 'id', 'value', 'name', 'type', 'style', 'href'];
  var INFLATE_TAG_TABLE = ['div', 'span', 'p', 'a'];
  function inflateTagName(tagName) {
    return typeof tagName === 'string' ? tagName : INFLATE_TAG_TABLE[tagName];
  }
  function inflateAttrName(attrName) {
    return typeof attrName === 'string' ? attrName : INFLATE_ATTR_TABLE[attrName];
  }
  STATEMENTS.add(3
  /* Comment */, sexp => op(41
  /* Comment */, sexp[1]));
  STATEMENTS.add(13
  /* CloseElement */, () => op(54
  /* CloseElement */));

  STATEMENTS.add(12
  /* FlushElement */, () => op(53
  /* FlushElement */));

  STATEMENTS.add(4
  /* Modifier */, (sexp, meta) => {
    var [, name, params, hash] = sexp;
    var stringName = expectString(name, meta, 'Expected modifier head to be a string');
    if (typeof stringName !== 'string') {
      return stringName;
    }
    return op('IfResolved', {
      kind: "Modifier"
      /* Modifier */,

      name: stringName,
      andThen: handle => [op(0
      /* PushFrame */), op('SimpleArgs', {
        params,
        hash,
        atNames: false
      }), op(56
      /* Modifier */, handle), op(1
      /* PopFrame */)],

      span: {
        start: 0,
        end: 0
      }
    });
  });
  STATEMENTS.add(14
  /* StaticAttr */, _ref18 => {
    var [, name, value, namespace] = _ref18;
    return op(50
    /* StaticAttr */, inflateAttrName(name), value, namespace !== null && namespace !== void 0 ? namespace : null);
  });
  STATEMENTS.add(24
  /* StaticComponentAttr */, _ref19 => {
    var [, name, value, namespace] = _ref19;
    return op(105
    /* StaticComponentAttr */, inflateAttrName(name), value, namespace !== null && namespace !== void 0 ? namespace : null);
  });
  STATEMENTS.add(15
  /* DynamicAttr */, _ref20 => {
    var [, name, value, namespace] = _ref20;
    return [op('Expr', value), op(51
    /* DynamicAttr */, inflateAttrName(name), false, namespace !== null && namespace !== void 0 ? namespace : null)];
  });
  STATEMENTS.add(22
  /* TrustingDynamicAttr */, _ref21 => {
    var [, name, value, namespace] = _ref21;
    return [op('Expr', value), op(51
    /* DynamicAttr */, inflateAttrName(name), true, namespace !== null && namespace !== void 0 ? namespace : null)];
  });
  STATEMENTS.add(16
  /* ComponentAttr */, _ref22 => {
    var [, name, value, namespace] = _ref22;
    return [op('Expr', value), op(52
    /* ComponentAttr */, inflateAttrName(name), false, namespace !== null && namespace !== void 0 ? namespace : null)];
  });
  STATEMENTS.add(23
  /* TrustingComponentAttr */, _ref23 => {
    var [, name, value, namespace] = _ref23;
    return [op('Expr', value), op(52
    /* ComponentAttr */, inflateAttrName(name), true, namespace !== null && namespace !== void 0 ? namespace : null)];
  });
  STATEMENTS.add(10
  /* OpenElement */, _ref24 => {
    var [, tag] = _ref24;
    return op(47
    /* OpenElement */, inflateTagName(tag));
  });
  STATEMENTS.add(11
  /* OpenElementWithSplat */, _ref25 => {
    var [, tag] = _ref25;
    return [op(89
    /* PutComponentOperations */), op(47
    /* OpenElement */, inflateTagName(tag))];
  });
  STATEMENTS.add(8
  /* Component */, _ref26 => {
    var [, tag, attrs, args, blocks] = _ref26;
    if (typeof tag === 'string') {
      return op('IfResolvedComponent', {
        name: tag,
        attrs,
        blocks,
        staticTemplate: (layoutHandle, capabilities, template, _ref27) => {
          var {
            blocks,
            attrs
          } = _ref27;
          return [op(77
          /* PushComponentDefinition */, layoutHandle), InvokeStaticComponent({
            capabilities,
            layout: template,
            attrs,
            params: null,
            hash: args,
            blocks
          })];
        },
        dynamicTemplate: (layoutHandle, capabilities, _ref28) => {
          var {
            attrs,
            blocks
          } = _ref28;
          return [op(77
          /* PushComponentDefinition */, layoutHandle), InvokeComponent({
            capabilities,
            attrs,
            params: null,
            hash: args,
            atNames: true,
            blocks
          })];
        }
      });
    } else {
      return op('DynamicComponent', {
        definition: tag,
        attrs,
        params: null,
        args,
        blocks,
        atNames: true,
        curried: true
      });
    }
  });
  STATEMENTS.add(19
  /* Partial */, (_ref29, meta) => {
    var [, name, evalInfo] = _ref29;
    return ReplayableIf({
      args() {
        return {
          count: 2,
          actions: [op('Expr', name), op(32
          /* Dup */, _vm.$sp, 0)]
        };
      },
      ifTrue() {
        return [op(101
        /* InvokePartial */, other(meta.owner), strArray(meta.evalSymbols), arr(evalInfo)), op(39
        /* PopScope */), op(1
        /* PopFrame */)];
      }
    });
  });

  STATEMENTS.add(18
  /* Yield */, _ref30 => {
    var [, to, params] = _ref30;
    return YieldBlock(to, params);
  });
  STATEMENTS.add(17
  /* AttrSplat */, _ref31 => {
    var [, to] = _ref31;
    return YieldBlock(to, _util.EMPTY_ARRAY);
  });
  STATEMENTS.add(26
  /* Debugger */, (_ref32, meta) => {
    var [, evalInfo] = _ref32;
    return op(103
    /* Debugger */, strArray(meta.evalSymbols), arr(evalInfo));
  });
  STATEMENTS.add(1
  /* Append */, sexp => {
    var [, value] = sexp;
    return op('CompileInline', {
      inline: sexp,
      ifUnhandled: () => [op(0
      /* PushFrame */), op("Expr"
      /* Expr */, value), op(3
      /* InvokeStatic */, {
        type: 'stdlib',
        value: 'cautious-append'
      }), op(1
      /* PopFrame */)]
    });
  });

  STATEMENTS.add(2
  /* TrustingAppend */, sexp => {
    var [, value] = sexp;
    if (typeof value === 'string') {
      return op(40
      /* Text */, value);
    } // macro was ignoring trusting flag doesn't seem like {{{}}} should
    // even be passed to macros, there is no {{{component}}}

    return [op(0
    /* PushFrame */), op("Expr"
    /* Expr */, value), op(3
    /* InvokeStatic */, {
      type: 'stdlib',
      value: 'trusting-append'
    }), op(1
    /* PopFrame */)];
  });

  STATEMENTS.add(6
  /* Block */, sexp => {
    return op('CompileBlock', sexp);
  });
  var PLACEHOLDER_HANDLE = -1;
  class CompilableTemplateImpl {
    constructor(statements, meta$$1,
    // Part of CompilableTemplate
    symbolTable) {
      this.statements = statements;
      this.meta = meta$$1;
      this.symbolTable = symbolTable;
      this.compiled = null;
    } // Part of CompilableTemplate

    compile(context) {
      return maybeCompile(this, context);
    }
  }
  function compilable(layout) {
    var block = layout.block;
    return new CompilableTemplateImpl(block.statements, meta(layout), {
      symbols: block.symbols,
      hasEval: block.hasEval
    });
  }
  function maybeCompile(compilable, context) {
    if (compilable.compiled !== null) return compilable.compiled;
    compilable.compiled = PLACEHOLDER_HANDLE;
    var {
      statements,
      meta: meta$$1
    } = compilable;
    var result = compileStatements(statements, meta$$1, context);
    (0, _program.patchStdlibs)(context.program);
    compilable.compiled = result;
    return result;
  }
  function compileStatements(statements, meta$$1, syntaxContext) {
    var sCompiler = STATEMENTS;
    var context = templateCompilationContext(syntaxContext, meta$$1);
    for (var i = 0; i < statements.length; i++) {
      concatStatements(context, sCompiler.compile(statements[i], context.meta));
    }
    var handle = context.encoder.commit(syntaxContext.program.heap, meta$$1.size);
    return handle;
  }
  function compilableBlock(overloadBlock, containing) {
    var block = Array.isArray(overloadBlock) ? {
      statements: overloadBlock,
      parameters: _util.EMPTY_ARRAY
    } : overloadBlock;
    return new CompilableTemplateImpl(block.statements, containing, {
      parameters: block.parameters
    });
  }
  class NamedBlocksImpl {
    constructor(blocks) {
      this.blocks = blocks;
      this.names = blocks ? Object.keys(blocks) : [];
    }
    get(name) {
      if (!this.blocks) return null;
      return this.blocks[name] || null;
    }
    has(name) {
      var {
        blocks
      } = this;
      return blocks !== null && name in blocks;
    }
    with(name, block) {
      var {
        blocks
      } = this;
      if (blocks) {
        return new NamedBlocksImpl((0, _util.assign)({}, blocks, {
          [name]: block
        }));
      } else {
        return new NamedBlocksImpl({
          [name]: block
        });
      }
    }
    get hasAny() {
      return this.blocks !== null;
    }
  }
  var EMPTY_BLOCKS = new NamedBlocksImpl(null);
  _exports.EMPTY_BLOCKS = EMPTY_BLOCKS;
  function namedBlocks(blocks, meta) {
    if (blocks === null) {
      return EMPTY_BLOCKS;
    }
    var out = (0, _util.dict)();
    var [keys, values] = blocks;
    for (var i = 0; i < keys.length; i++) {
      out[keys[i]] = compilableBlock(values[i], meta);
    }
    return new NamedBlocksImpl(out);
  }
  function expectString(expr, meta, desc) {
    if (!meta.upvars) {
      return error(`${desc}, but there were no free variables in the template`, 0, 0);
    }
    if (!Array.isArray(expr)) {
      throw new Error(`${desc}, got ${JSON.stringify(expr)}`);
    }
    if (isGet(expr)) {
      var name = simplePathName(expr, meta);
      if (name !== null) return name;
    }
    throw new Error(`${desc}, got ${JSON.stringify(expr)}`);
  }
  function simplePathName(opcode, meta) {
    if (opcode.length === 3 && opcode[2].length > 0) {
      return null;
    }
    if (isGetFree(opcode)) {
      return meta.upvars[opcode[1]];
    }
    return null;
  }
  function isGet(opcode) {
    return opcode.length >= 2 && opcode[0] >= 32
    /* GetSymbol */;
  }

  function isGetFree(opcode) {
    return opcode[0] >= 33
    /* GetFree */;
  }

  function compileInline(sexp, context) {
    return context.syntax.macros.inlines.compile(sexp, context);
  }
  function compileBlock(block, context) {
    var [, name, params, hash, named] = block;
    var blocks = namedBlocks(named, context.meta);
    var nameOrError = expectString(name, context.meta, 'Expected block head to be a string');
    if (typeof nameOrError !== 'string') {
      return nameOrError;
    }
    return context.syntax.macros.blocks.compile(nameOrError, params || [], hash, blocks, context);
  }
  function commit(heap, scopeSize, buffer) {
    var handle = heap.malloc();
    for (var i = 0; i < buffer.length; i++) {
      var value = buffer[i];
      if (typeof value === 'function') {
        heap.pushPlaceholder(value);
      } else if (typeof value === 'object') {
        heap.pushStdlib(value);
      } else {
        heap.push(value);
      }
    }
    heap.finishMalloc(handle, scopeSize);
    return handle;
  }
  var debugCompiler;
  _exports.debugCompiler = debugCompiler;
  class LabelsImpl {
    constructor() {
      this.labels = (0, _util.dict)();
      this.targets = [];
    }
    label(name, index) {
      this.labels[name] = index;
    }
    target(at, target) {
      this.targets.push({
        at,
        target
      });
    }
    patch(encoder) {
      var {
        targets,
        labels
      } = this;
      for (var i = 0; i < targets.length; i++) {
        var {
          at,
          target
        } = targets[i];
        var address = labels[target] - at;
        encoder.patch(at, address);
      }
    }
  }
  function error(problem, start, end) {
    return op('Error', {
      problem,
      start,
      end
    });
  }
  function op(name, op1, op2, op3) {
    if (typeof name === 'number') {
      if (op3 !== undefined) {
        return {
          type: 'Number',
          op: name,
          op1,
          op2,
          op3
        };
      } else if (op2 !== undefined) {
        return {
          type: 'Number',
          op: name,
          op1,
          op2
        };
      } else if (op1 !== undefined) {
        return {
          type: 'Number',
          op: name,
          op1: op1
        };
      } else {
        return {
          type: 'Number',
          op: name
        };
      }
    } else {
      var type;
      if (isCompileOpcode(name)) {
        type = 'Compile';
      } else if (isResolutionOpcode(name)) {
        type = 'Resolution';
      } else if (isSimpleOpcode(name)) {
        type = 'Simple';
      } else if (isErrorOpcode(name)) {
        type = 'Error';
      } else {
        throw new Error(`Exhausted ${name}`);
      }
      if (op1 === undefined) {
        return {
          type,
          op: name,
          op1: undefined
        };
      } else {
        return {
          type,
          op: name,
          op1
        };
      }
    }
  }
  class EncoderImpl {
    constructor() {
      this.labelsStack = new _util.Stack();
      this.encoder = new _encoder.InstructionEncoderImpl([]);
      this.errors = [];
    }
    error(error) {
      this.encoder.encode(29
      /* Primitive */, 0);
      this.errors.push(error);
    }
    commit(heap, size) {
      this.encoder.encode(5
      /* Return */, 1024
      /* MACHINE_MASK */);

      var handle = commit(heap, size, this.encoder.buffer);
      if (this.errors.length) {
        return {
          errors: this.errors,
          handle
        };
      } else {
        return handle;
      }
    }
    push(constants, name) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }
      if ((0, _vm.isMachineOp)(name)) {
        var operands = args.map((operand, i) => this.operand(constants, operand, i));
        return this.encoder.encode(name, 1024
        /* MACHINE_MASK */, ...operands);
      } else {
        var _operands = args.map((operand, i) => this.operand(constants, operand, i));
        return this.encoder.encode(name, 0, ..._operands);
      }
    }
    operand(constants, operand, index) {
      if (operand && typeof operand === 'object' && operand.type === 'label') {
        this.currentLabels.target(this.encoder.size + index, operand.value);
        return -1;
      }
      return constant(constants, operand);
    }
    get currentLabels() {
      return this.labelsStack.current;
    }
    label(name) {
      this.currentLabels.label(name, this.encoder.size);
    }
    startLabels() {
      this.labelsStack.push(new LabelsImpl());
    }
    stopLabels() {
      var label = this.labelsStack.pop();
      label.patch(this.encoder);
    }
  }
  function constant(constants, operand) {
    if (typeof operand === 'number' || typeof operand === 'function') {
      return operand;
    }
    if (typeof operand === 'boolean') {
      return operand === true ? 1 : 0;
    }
    if (typeof operand === 'string') {
      return constants.value(operand);
    }
    if (operand === null) {
      return 0;
    }
    switch (operand.type) {
      case 'string-array':
        return constants.array(operand.value);
      case 'serializable':
        return constants.serializable(operand.value);
      case 'stdlib':
        return operand;
      case 'immediate':
        return (0, _util.encodeImmediate)(operand.value);
      case 'primitive':
      case 'array':
      case 'other':
        return (0, _util.encodeHandle)(constants.value(operand.value));
      case 'lookup':
        throw (0, _util.unreachable)('lookup not reachable');
      default:
        return (0, _util.exhausted)(operand);
    }
  }
  function isSimpleOpcode(op) {
    return op === 'Label' || op === 'Option' || op === 'StartLabels' || op === 'StopLabels';
  }
  function isCompileOpcode(op) {
    return op === 'CompileInline' || op === 'CompileBlock' || op === 'IfResolvedComponent' || op === 'DynamicComponent';
  }
  function isResolutionOpcode(op) {
    return op === 'IfResolved' || op === 'Expr' || op === 'SimpleArgs' || op === 'ResolveFree' || op === 'ResolveContextualFree';
  }
  function isErrorOpcode(op) {
    return op === 'Error';
  }
  /**
   * Compile arguments, pushing an Arguments object onto the stack.
   *
   * @param args.params
   * @param args.hash
   * @param args.blocks
   * @param args.atNames
   */

  function CompileArgs(_ref33) {
    var {
      params,
      hash,
      blocks,
      atNames
    } = _ref33;
    var out = [];
    var blockNames = blocks.names;
    for (var i = 0; i < blockNames.length; i++) {
      out.push(PushYieldableBlock(blocks.get(blockNames[i])));
    }
    var {
      count,
      actions
    } = CompilePositional(params);
    out.push(actions);
    var flags = count << 4;
    if (atNames) flags |= 0b1000;
    if (blocks) {
      flags |= 0b111;
    }
    var names = _util.EMPTY_ARRAY;
    if (hash) {
      names = hash[0];
      var val = hash[1];
      for (var _i = 0; _i < val.length; _i++) {
        out.push(op('Expr', val[_i]));
      }
    }
    out.push(op(82
    /* PushArgs */, strArray(names), strArray(blockNames), flags));
    return out;
  }
  /**
   * Compile an optional list of positional arguments, which pushes each argument
   * onto the stack and returns the number of parameters compiled
   *
   * @param params an optional list of positional arguments
   */

  function CompilePositional(params) {
    if (!params) return {
      count: 0,
      actions: NONE
    };
    var actions = [];
    for (var i = 0; i < params.length; i++) {
      actions.push(op('Expr', params[i]));
    }
    return {
      count: params.length,
      actions
    };
  }
  function meta(layout) {
    return {
      asPartial: layout.asPartial || false,
      evalSymbols: evalSymbols(layout),
      upvars: layout.block.upvars,
      moduleName: layout.moduleName,
      owner: layout.owner,
      size: layout.block.symbols.length
    };
  }
  function evalSymbols(layout) {
    var {
      block
    } = layout;
    return block.hasEval ? block.symbols : null;
  }
  var ATTRS_BLOCK = '&attrs';
  function StaticComponentHelper(context, tag, hash, template) {
    var component = resolveLayoutForTag(tag, context);
    if (component !== null) {
      var {
        compilable: compilable$$1,
        handle,
        capabilities
      } = component;
      if (compilable$$1) {
        if (hash) {
          for (var i = 0; i < hash[0].length; i = i + 1) {
            hash[0][i] = `@${hash[0][i]}`;
          }
        }
        var out = [op(77
        /* PushComponentDefinition */, handle)];
        out.push(InvokeStaticComponent({
          capabilities,
          layout: compilable$$1,
          attrs: null,
          params: null,
          hash,
          blocks: new NamedBlocksImpl({
            default: template
          })
        }));
        return out;
      }
    }
    return UNHANDLED;
  }
  function InvokeStaticComponent(_ref34) {
    var {
      capabilities,
      layout,
      attrs,
      params,
      hash,
      blocks
    } = _ref34;
    var {
      symbolTable
    } = layout;
    var bailOut = symbolTable.hasEval || capabilities.prepareArgs;
    if (bailOut) {
      return InvokeComponent({
        capabilities,
        attrs,
        params,
        hash,
        atNames: true,
        blocks,
        layout
      });
    }
    var out = [op(35
    /* Fetch */, _vm.$s0), op(32
    /* Dup */, _vm.$sp, 1), op(34
    /* Load */, _vm.$s0), op(0
    /* PushFrame */)]; // Setup arguments

    var {
      symbols
    } = symbolTable; // As we push values onto the stack, we store the symbols associated  with them
    // so that we can set them on the scope later on with SetVariable and SetBlock

    var blockSymbols = [];
    var argSymbols = [];
    var argNames = []; // First we push the blocks onto the stack

    var blockNames = blocks.names; // Starting with the attrs block, if it exists and is referenced in the component

    if (attrs !== null) {
      var symbol = symbols.indexOf(ATTRS_BLOCK);
      if (symbol !== -1) {
        out.push(PushYieldableBlock(attrs));
        blockSymbols.push(symbol);
      }
    } // Followed by the other blocks, if they exist and are referenced in the component.
    // Also store the index of the associated symbol.

    for (var i = 0; i < blockNames.length; i++) {
      var name = blockNames[i];
      var _symbol = symbols.indexOf(`&${name}`);
      if (_symbol !== -1) {
        out.push(PushYieldableBlock(blocks.get(name)));
        blockSymbols.push(_symbol);
      }
    } // Next up we have arguments. If the component has the `createArgs` capability,
    // then it wants access to the arguments in JavaScript. We can't know whether
    // or not an argument is used, so we have to give access to all of them.

    if (capabilities.createArgs) {
      // First we push positional arguments
      var {
        count,
        actions
      } = CompilePositional(params);
      out.push(actions); // setup the flags with the count of positionals, and to indicate that atNames
      // are used

      var flags = count << 4;
      flags |= 0b1000;
      var names = _util.EMPTY_ARRAY; // Next, if named args exist, push them all. If they have an associated symbol
      // in the invoked component (e.g. they are used within its template), we push
      // that symbol. If not, we still push the expression as it may be used, and
      // we store the symbol as -1 (this is used later).

      if (hash !== null) {
        names = hash[0];
        var val = hash[1];
        for (var _i2 = 0; _i2 < val.length; _i2++) {
          var _symbol2 = symbols.indexOf(names[_i2]);
          out.push(op('Expr', val[_i2]));
          argSymbols.push(_symbol2);
        }
      } // Finally, push the VM arguments themselves. These args won't need access
      // to blocks (they aren't accessible from userland anyways), so we push an
      // empty array instead of the actual block names.

      out.push(op(82
      /* PushArgs */, strArray(names), strArray(_util.EMPTY_ARRAY), flags)); // And push an extra pop operation to remove the args before we begin setting
      // variables on the local context

      argSymbols.push(-1);
    } else if (hash !== null) {
      // If the component does not have the `createArgs` capability, then the only
      // expressions we need to push onto the stack are those that are actually
      // referenced in the template of the invoked component (e.g. have symbols).
      var _names = hash[0];
      var _val = hash[1];
      for (var _i3 = 0; _i3 < _val.length; _i3++) {
        var _name4 = _names[_i3];
        var _symbol3 = symbols.indexOf(_name4);
        if (_symbol3 !== -1) {
          out.push(op('Expr', _val[_i3]));
          argSymbols.push(_symbol3);
          argNames.push(_name4);
        }
      }
    }
    out.push(op(97
    /* BeginComponentTransaction */, _vm.$s0));
    if (capabilities.dynamicScope) {
      out.push(op(58
      /* PushDynamicScope */));
    }

    if (capabilities.createInstance) {
      out.push(op(87
      /* CreateComponent */, blocks.has('default') | 0, _vm.$s0));
    }
    out.push(op(88
    /* RegisterComponentDestructor */, _vm.$s0));
    if (capabilities.createArgs) {
      out.push(op(90
      /* GetComponentSelf */, _vm.$s0));
    } else {
      out.push(op(90
      /* GetComponentSelf */, _vm.$s0, other(argNames)));
    }
    out.push(
    // Setup the new root scope for the component
    op(36
    /* RootScope */, symbols.length + 1, Object.keys(blocks).length > 0 ? 1 : 0),
    // Pop the self reference off the stack and set it to the symbol for `this`
    // in the new scope. This is why all subsequent symbols are increased by one.
    op(19
    /* SetVariable */, 0)); // Going in reverse, now we pop the args/blocks off the stack, starting with
    // arguments, and assign them to their symbols in the new scope.

    for (var _i4 = argSymbols.length - 1; _i4 >= 0; _i4--) {
      var _symbol4 = argSymbols[_i4];
      if (_symbol4 === -1) {
        // The expression was not bound to a local symbol, it was only pushed to be
        // used with VM args in the javascript side
        out.push(op(33
        /* Pop */, 1));
      } else {
        out.push(op(19
        /* SetVariable */, _symbol4 + 1));
      }
    } // if any positional params exist, pop them off the stack as well

    if (params !== null) {
      out.push(op(33
      /* Pop */, params.length));
    } // Finish up by popping off and assigning blocks

    for (var _i5 = blockSymbols.length - 1; _i5 >= 0; _i5--) {
      var _symbol5 = blockSymbols[_i5];
      out.push(op(20
      /* SetBlock */, _symbol5 + 1));
    }
    out.push([op(28
    /* Constant */, other(layout)), op(60
    /* CompileBlock */), op(2
    /* InvokeVirtual */)]);

    out.push(op(100
    /* DidRenderLayout */, _vm.$s0));
    out.push(op(1
    /* PopFrame */), op(39
    /* PopScope */));

    if (capabilities.dynamicScope) {
      out.push(op(59
      /* PopDynamicScope */));
    }

    out.push(op(98
    /* CommitComponentTransaction */), op(34
    /* Load */, _vm.$s0));
    return out;
  }
  function InvokeDynamicComponent(meta$$1, _ref35) {
    var {
      definition,
      attrs,
      params,
      hash,
      atNames,
      blocks,
      curried
    } = _ref35;
    return Replayable({
      args: () => {
        return {
          count: 2,
          actions: [op('Expr', definition), op(32
          /* Dup */, _vm.$sp, 0)]
        };
      },
      body: () => {
        return [op(65
        /* JumpUnless */, label('ELSE')), curried ? op(81
        /* ResolveCurriedComponent */) : op(80
        /* ResolveDynamicComponent */, other(meta$$1.owner)), op(78
        /* PushDynamicComponentInstance */), InvokeComponent({
          capabilities: true,
          attrs,
          params,
          hash,
          atNames,
          blocks
        }), op('Label', 'ELSE')];
      }
    });
  }
  function WrappedComponent(layout, attrsBlockNumber) {
    return [op('StartLabels'), WithSavedRegister(_vm.$s1, () => [op(91
    /* GetComponentTagName */, _vm.$s0), op(30
    /* PrimitiveReference */), op(32
    /* Dup */, _vm.$sp, 0)]), op(65
    /* JumpUnless */, label('BODY')), op(35
    /* Fetch */, _vm.$s1), op(89
    /* PutComponentOperations */), op(48
    /* OpenDynamicElement */), op(99
    /* DidCreateElement */, _vm.$s0), YieldBlock(attrsBlockNumber, _util.EMPTY_ARRAY), op(53
    /* FlushElement */), op('Label', 'BODY'), InvokeStaticBlock(blockForLayout(layout)), op(35
    /* Fetch */, _vm.$s1), op(65
    /* JumpUnless */, label('END')), op(54
    /* CloseElement */), op('Label', 'END'), op(34
    /* Load */, _vm.$s1), op('StopLabels')];
  }
  function StaticComponent(component, args) {
    var [params, hash, blocks] = args;
    if (component === null) return NONE;
    var {
      compilable: compilable$$1,
      capabilities,
      handle
    } = component;
    if (compilable$$1) {
      return [op(77
      /* PushComponentDefinition */, handle), InvokeStaticComponent({
        capabilities: capabilities || MINIMAL_CAPABILITIES,
        layout: compilable$$1,
        attrs: null,
        params,
        hash,
        blocks
      })];
    } else {
      return [op(77
      /* PushComponentDefinition */, handle), InvokeComponent({
        capabilities: capabilities || MINIMAL_CAPABILITIES,
        attrs: null,
        params,
        hash,
        atNames: true,
        blocks
      })];
    }
  }
  function InvokeComponent(_ref36) {
    var {
      capabilities,
      attrs,
      params,
      hash,
      atNames,
      blocks: namedBlocks$$1,
      layout
    } = _ref36;
    var bindableBlocks = !!namedBlocks$$1;
    var bindableAtNames = capabilities === true || capabilities.prepareArgs || !!(hash && hash[0].length !== 0);
    var blocks = namedBlocks$$1.with('attrs', attrs);
    return [op(35
    /* Fetch */, _vm.$s0), op(32
    /* Dup */, _vm.$sp, 1), op(34
    /* Load */, _vm.$s0), op(0
    /* PushFrame */), CompileArgs({
      params,
      hash,
      blocks,
      atNames
    }), op(85
    /* PrepareArgs */, _vm.$s0), invokePreparedComponent(blocks.has('default'), bindableBlocks, bindableAtNames, () => {
      var out;
      if (layout) {
        out = [PushSymbolTable(layout.symbolTable), PushCompilable(layout), op(60
        /* CompileBlock */)];
      } else {
        out = [op(92
        /* GetComponentLayout */, _vm.$s0)];
      }
      out.push(op(95
      /* PopulateLayout */, _vm.$s0));
      return out;
    }), op(34
    /* Load */, _vm.$s0)];
  }
  function invokePreparedComponent(hasBlock, bindableBlocks, bindableAtNames, populateLayout) {
    if (populateLayout === void 0) {
      populateLayout = null;
    }
    var out = [op(97
    /* BeginComponentTransaction */, _vm.$s0), op(58
    /* PushDynamicScope */), op(87
    /* CreateComponent */, hasBlock | 0, _vm.$s0)]; // this has to run after createComponent to allow
    // for late-bound layouts, but a caller is free
    // to populate the layout earlier if it wants to
    // and do nothing here.

    if (populateLayout) {
      out.push(populateLayout());
    }
    out.push(op(88
    /* RegisterComponentDestructor */, _vm.$s0), op(90
    /* GetComponentSelf */, _vm.$s0), op(37
    /* VirtualRootScope */, _vm.$s0), op(19
    /* SetVariable */, 0), op(94
    /* SetupForEval */, _vm.$s0), bindableAtNames ? op(17
    /* SetNamedVariables */, _vm.$s0) : NONE, bindableBlocks ? op(18
    /* SetBlocks */, _vm.$s0) : NONE, op(33
    /* Pop */, 1), op(96
    /* InvokeComponentLayout */, _vm.$s0), op(100
    /* DidRenderLayout */, _vm.$s0), op(1
    /* PopFrame */), op(39
    /* PopScope */), op(59
    /* PopDynamicScope */), op(98
    /* CommitComponentTransaction */));

    return out;
  }
  function InvokeBareComponent() {
    return [op(35
    /* Fetch */, _vm.$s0), op(32
    /* Dup */, _vm.$sp, 1), op(34
    /* Load */, _vm.$s0), op(0
    /* PushFrame */), op(83
    /* PushEmptyArgs */), op(85
    /* PrepareArgs */, _vm.$s0), invokePreparedComponent(false, false, true, () => [op(92
    /* GetComponentLayout */, _vm.$s0), op(95
    /* PopulateLayout */, _vm.$s0)]), op(34
    /* Load */, _vm.$s0)];
  }
  function curryComponent(_ref37, owner) {
    var {
      definition,
      params,
      hash,
      atNames
    } = _ref37;
    return [op(0
    /* PushFrame */), op('SimpleArgs', {
      params,
      hash,
      atNames
    }), op(86
    /* CaptureArgs */), op('Expr', definition), op(76
    /* CurryComponent */, other(owner)), op(1
    /* PopFrame */), op(35
    /* Fetch */, _vm.$v0)];
  }
  function blockForLayout(layout) {
    return compilableBlock(layout.block.statements, meta(layout));
  }
  function WithSavedRegister(register, block) {
    return [op(35
    /* Fetch */, register), block(), op(34
    /* Load */, register)];
  }
  class StdLib {
    constructor(main, trustingGuardedAppend, cautiousGuardedAppend) {
      this.main = main;
      this.trustingGuardedAppend = trustingGuardedAppend;
      this.cautiousGuardedAppend = cautiousGuardedAppend;
    }
    get 'trusting-append'() {
      return this.trustingGuardedAppend;
    }
    get 'cautious-append'() {
      return this.cautiousGuardedAppend;
    }
    getAppend(trusting) {
      return trusting ? this.trustingGuardedAppend : this.cautiousGuardedAppend;
    }
  }
  _exports.StdLib = StdLib;
  function main() {
    return [op(74
    /* Main */, _vm.$s0), invokePreparedComponent(false, false, true)];
  }
  /**
   * Append content to the DOM. This standard function triages content and does the
   * right thing based upon whether it's a string, safe string, component, fragment
   * or node.
   *
   * @param trusting whether to interpolate a string as raw HTML (corresponds to
   * triple curlies)
   */

  function StdAppend(trusting) {
    return [ContentTypeSwitchCases(when => {
      when(1
      /* String */, () => {
        if (trusting) {
          return [op(67
          /* AssertSame */), op(42
          /* AppendHTML */)];
        } else {
          return op(46
          /* AppendText */);
        }
      });

      when(0
      /* Component */, () => [op(79
      /* PushCurriedComponent */), op(78
      /* PushDynamicComponentInstance */), InvokeBareComponent()]);
      when(3
      /* SafeString */, () => [op(67
      /* AssertSame */), op(43
      /* AppendSafeHTML */)]);

      when(4
      /* Fragment */, () => [op(67
      /* AssertSame */), op(44
      /* AppendDocumentFragment */)]);

      when(5
      /* Node */, () => [op(67
      /* AssertSame */), op(45
      /* AppendNode */)]);
    })];
  }

  function compileStd(context) {
    var mainHandle = build(context, main);
    var trustingGuardedAppend = build(context, () => StdAppend(true));
    var cautiousGuardedAppend = build(context, () => StdAppend(false));
    return new StdLib(mainHandle, trustingGuardedAppend, cautiousGuardedAppend);
  }
  var STDLIB_META = {
    asPartial: false,
    evalSymbols: null,
    upvars: null,
    moduleName: 'stdlib',
    // TODO: ??
    owner: null,
    size: 0
  };
  function build(program, callback) {
    var encoder = new EncoderImpl();
    var macros = new MacrosImpl();
    var stdContext = {
      encoder,
      meta: STDLIB_META,
      syntax: {
        macros,
        program
      }
    };
    concat(stdContext, callback());
    var result = encoder.commit(program.heap, 0);
    if (typeof result !== 'number') {
      // This shouldn't be possible
      throw new Error(`Unexpected errors compiling std`);
    } else {
      return result;
    }
  }
  class CompileTimeCompilationContextImpl {
    constructor(_ref38, resolver) {
      var {
        constants,
        heap
      } = _ref38;
      this.resolver = resolver;
      this.constants = constants;
      this.heap = heap;
      this.stdlib = compileStd(this);
    }
  }
  _exports.CompileTimeCompilationContextImpl = CompileTimeCompilationContextImpl;
  class PartialDefinitionImpl {
    constructor(name,
    // for debugging
    template) {
      this.name = name;
      this.template = template;
    }
    getPartial(context) {
      var partial = (0, _util.unwrapTemplate)(this.template).asPartial();
      var handle = partial.compile(context);
      return {
        symbolTable: partial.symbolTable,
        handle
      };
    }
  }
  _exports.PartialDefinitionImpl = PartialDefinitionImpl;
  class WrappedBuilder {
    constructor(layout) {
      this.layout = layout;
      this.compiled = null;
      var {
        block
      } = layout;
      var symbols = block.symbols.slice(); // ensure ATTRS_BLOCK is always included (only once) in the list of symbols

      var attrsBlockIndex = symbols.indexOf(ATTRS_BLOCK);
      if (attrsBlockIndex === -1) {
        this.attrsBlockNumber = symbols.push(ATTRS_BLOCK);
      } else {
        this.attrsBlockNumber = attrsBlockIndex + 1;
      }
      this.symbolTable = {
        hasEval: block.hasEval,
        symbols
      };
    }
    compile(syntax) {
      if (this.compiled !== null) return this.compiled;
      var m = meta(this.layout);
      var context = templateCompilationContext(syntax, m);
      var actions = WrappedComponent(this.layout, this.attrsBlockNumber);
      concatStatements(context, actions);
      var handle = context.encoder.commit(context.syntax.program.heap, m.size);
      if (typeof handle !== 'number') {
        return handle;
      }
      this.compiled = handle;
      (0, _program.patchStdlibs)(context.syntax.program);
      return handle;
    }
  }
  _exports.WrappedBuilder = WrappedBuilder;
  var clientId = 0;
  var templateCacheCounters = {
    cacheHit: 0,
    cacheMiss: 0
  };
  /**
   * Wraps a template js in a template module to change it into a factory
   * that handles lazy parsing the template and to create per env singletons
   * of the template.
   */
  _exports.templateCacheCounters = templateCacheCounters;
  function templateFactory(_ref39) {
    var {
      id: templateId,
      moduleName,
      block
    } = _ref39;
    // TODO(template-refactors): This should be removed in the near future, as it
    // appears that id is unused. It is currently kept for backwards compat reasons.
    var id = templateId || `client-${clientId++}`; // TODO: This caches JSON serialized output once in case a template is
    // compiled by multiple owners, but we haven't verified if this is actually
    // helpful. We should benchmark this in the future.

    var parsedBlock;
    var ownerlessTemplate = null;
    var templateCache = new WeakMap();
    var factory = owner => {
      if (parsedBlock === undefined) {
        parsedBlock = JSON.parse(block);
      }
      if (owner === undefined) {
        if (ownerlessTemplate === null) {
          templateCacheCounters.cacheMiss++;
          ownerlessTemplate = new TemplateImpl({
            id,
            block: parsedBlock,
            moduleName,
            owner: null
          });
        } else {
          templateCacheCounters.cacheHit++;
        }
        return ownerlessTemplate;
      }
      var result = templateCache.get(owner);
      if (result === undefined) {
        templateCacheCounters.cacheMiss++;
        result = new TemplateImpl({
          id,
          block: parsedBlock,
          moduleName,
          owner
        });
        templateCache.set(owner, result);
      } else {
        templateCacheCounters.cacheHit++;
      }
      return result;
    };
    factory.__id = id;
    factory.__meta = {
      moduleName
    };
    return factory;
  }
  class TemplateImpl {
    constructor(parsedLayout) {
      this.parsedLayout = parsedLayout;
      this.result = 'ok';
      this.layout = null;
      this.partial = null;
      this.wrappedLayout = null;
    }
    get moduleName() {
      return this.parsedLayout.moduleName;
    }
    get id() {
      return this.parsedLayout.id;
    } // TODO(template-refactors): This should be removed in the near future, it is
    // only being exposed for backwards compatibility

    get referrer() {
      return {
        moduleName: this.parsedLayout.moduleName,
        owner: this.parsedLayout.owner
      };
    }
    asLayout() {
      if (this.layout) return this.layout;
      return this.layout = compilable((0, _util.assign)({}, this.parsedLayout, {
        asPartial: false
      }));
    }
    asPartial() {
      if (this.partial) return this.partial;
      return this.layout = compilable((0, _util.assign)({}, this.parsedLayout, {
        asPartial: true
      }));
    }
    asWrappedLayout() {
      if (this.wrappedLayout) return this.wrappedLayout;
      return this.wrappedLayout = new WrappedBuilder((0, _util.assign)({}, this.parsedLayout, {
        asPartial: false
      }));
    }
  }
});