define("ember-named-blocks-polyfill/helpers/-has-block", ["exports", "ember-named-blocks-polyfill"], function (_exports, _emberNamedBlocksPolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(
  /**
   * @param {unknown[]} params
   * @param {object} hash
   * @returns {boolean}
   */
  function _hasBlock(params, hash) {
    let [blocksInfo, block, fallback] = params;
    (true && !(params.length === 3 && (blocksInfo === undefined || (0, _emberNamedBlocksPolyfill.isBlocksInfo)(blocksInfo)) && typeof block === 'string' && typeof fallback === 'boolean') && Ember.assert('-has-block takes exactly three arguments: the blocks info ' + 'hash, the name of the block and the fallback value', params.length === 3 && (blocksInfo === undefined || (0, _emberNamedBlocksPolyfill.isBlocksInfo)(blocksInfo)) && typeof block === 'string' && typeof fallback === 'boolean'));
    (true && !(Object.keys(hash).length === 0) && Ember.assert('-is-named-block-invocation does not take named arguments', Object.keys(hash).length === 0));
    return blocksInfo ? block in blocksInfo : fallback;
  });
  _exports.default = _default;
});