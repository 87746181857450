define('ember-cli-is-component/services/is-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  const { Service, getOwner } = Ember;

  exports.default = Service.extend({
    test(name) {
      name = (name || '').trim();

      if (!name) {
        return false;
      }

      const owner = getOwner(this);
      const lookup = owner.lookup('component-lookup:main');

      if (!lookup.componentFor) {
        return !!lookup.lookupFactory(name);
      }

      return !!(lookup.componentFor(name, owner) || lookup.layoutFor(name, owner));
    }
  });
});